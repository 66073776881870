import React, { useEffect, useState } from 'react'
import './Bonus.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'


const Bonus = (props) => {
    const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), mhrate: 1, gayrate: 1 });
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [DataSource, setDataSource] = useState([]);
    const [DataSource2, setDataSource2] = useState([]);
    const [wait, setWait] = useState(false);


    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date2') {
            // Check if date2 is more than one year ahead of date1
            const date1 = new Date(input.date1);
            const date2 = new Date(valuecurrent);
            const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000; // Approximate milliseconds in a year
            if (date2 - date1 <= oneYearInMilliseconds) {
                setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
            } else {
                // Display an error or prevent setting the state if date2 is more than one year ahead
                // You can add your error handling logic here
                notifyerror("रिपोर्ट लिमिट - 1 वर्ष");
                setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
            }
        }
    }

    useEffect(() => {
        getthevmaxdate();
    }, [])


    const getthevmaxdate = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/getthevmaxdate.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);

            if (response.data && response.data.length > 0) {
                setDataSource2(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const showprint = () => {
        if (DataSource && DataSource.length > 0) {
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            localStorage.setItem("mhrate", input.mhrate);
            localStorage.setItem("gayrate", input.gayrate);
            const url = "/bonusprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे संकलन उपलब्ध नाही!");
        }
    }

    const getbonusreport = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/getbousreport.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
                setWait(false);
            }
            else {
                notifyerror("पुन्हा प्रयत्न करा.");
                setDataSource([]);
                setWait(false);
            }
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <div className="sanklanmain">
            <div className="firstrowsank">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmainbill">
                    <div className="firstrowbill">
                        <div className="rowbill">
                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                            </div>

                            <span className='subheadbill'>ते</span>

                            <div className="mb-2">
                                <span className='subheadsank'>दिनांक</span>
                                <input type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                            </div>

                            <div className="mb-2">
                                {
                                    wait ?
                                        <button type="submit" id='show' className="btn btn-primary mx-1 sanksmall"></button>
                                        :
                                        <button onClick={getbonusreport} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>


                                }
                                <button onClick={showprint} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>

                            </div>

                            <div className="rowbill bonusbox">
                                <div className="mb-2 bonustext2">
                                    <span className='subheadsank'>म्हैस दर/लिटर</span>
                                    <input type="text" className="form-control bonustext" autoComplete='off' id="mhrate" name="mhrate" value={input.mhrate} onChange={handleChange} ></input>
                                </div>
                                <div className="mb-2 bonustext2">
                                    <span className='subheadsank'>गाय दर/लिटर</span>
                                    <input type="text" className="form-control bonustext" autoComplete='off' id="gayrate" name="gayrate" value={input.gayrate} onChange={handleChange} ></input>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="firstrow2">
                <div class="table-containerthev">
                    <table id="myTablebill" className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='thsmall' scope="col">खाते क्रमांक</th>
                                <th className='thlarge' scope="col">नाव</th>
                                <th className='thsmall' scope="col">म्हैस लीटर</th>
                                <th className='thsmall' scope="col">म्हैस बोनस</th>
                                <th className='thsmall' scope="col">गाय लीटर</th>
                                <th className='thsmall' scope="col">गाय बोनस</th>
                                <th className='thsmall' scope="col">एकूण बोनस</th>

                            </tr>
                        </thead>
                        <tbody>
                            {DataSource && DataSource.map((user, index) =>
                                <tr key={index}>
                                    <td className='thsmall'>{user.kno}</td>
                                    <td className='thsmall'>{user.cname}</td>
                                    <td className='thsmall2'>{user.mhliter ? parseFloat(user.mhliter).toFixed(2) : 0}</td>
                                    <td className='thsmall2'>{user.mhliter ? parseFloat(user.mhliter * parseFloat(input.mhrate)).toFixed(2) : 0}</td>
                                    <td className='thsmall2'>{user.gayliter ? parseFloat(user.gayliter).toFixed(2) : 0}</td>
                                    <td className='thsmall2'>{user.gayliter ? parseFloat(user.gayliter * parseFloat(input.gayrate)).toFixed(2) : 0}</td>
                                    <td className='thsmallgreenbonus'>{parseFloat(parseFloat(user.mhliter * parseFloat(input.mhrate)) + parseFloat(user.gayliter * parseFloat(input.gayrate))).toFixed(2)}</td>
                                </tr>
                            )
                            }
                        </tbody>
                        <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                            <td className='thsmallcust'>एकूण</td>
                            <td className='thsmallcust'>-</td>
                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + (user.mhliter && user.mhliter != null ? parseFloat(user.mhliter) : 0), 0).toFixed(2)}</td>
                            <td className='thsmallcust'>
                                {DataSource.reduce(
                                    (total, user) =>
                                        total +
                                        (parseFloat(user.mhliter) * (input.mhrate ? parseFloat(input.mhrate) : 0)),
                                    0 // Provide an initial value of 0
                                ).toFixed(2)}
                            </td>

                            <td className='thsmallcust'>{DataSource.reduce((total, user) => total + (user.gayliter && user.gayliter != null ? parseFloat(user.gayliter) : 0), 0).toFixed(2)}</td>
                            <td className='thsmallcust'>
                                {DataSource.reduce(
                                    (total, user) =>
                                        total +
                                        (parseFloat(user.gayliter) * (input.gayrate ? parseFloat(input.gayrate) : 0)),
                                    0 // Provide an initial value of 0
                                ).toFixed(2)}
                            </td>
                            <td className='thsmallcust'>
                                {DataSource.reduce(
                                    (total, user) =>
                                        total +
                                        (parseFloat(user.gayliter) * (input.gayrate ? parseFloat(input.gayrate) : 0)) + (parseFloat(user.mhliter) * (input.mhrate ? parseFloat(input.mhrate) : 0)),
                                    0 // Provide an initial value of 0
                                ).toFixed(2)}
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Bonus
