import React, { useEffect, useRef, useState } from 'react'
import './Settings2.css'
import back from "../../imgs/backexam.png";
import milkcan from "../../imgs/milkcan.png";
import farmer from "../../imgs/farmer.png";
import khadya from "../../imgs/khadya.png";
import cash from "../../imgs/cash.png";
import time from "../../imgs/time.png";
import weight from "../../imgs/wightmachine.png";

import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';

const Settings = (props) => {


    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ suchana: ""});
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);

    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');

    const [literauto, setLiterauto] = useState('बंद');
    const [fatauto, setFatauto] = useState('बंद');

    const [sanknew, setSanknew] = useState('चालू');
    const [sankedit, setSankedit] = useState('चालू');
    const [sankdelete, setSankdelete] = useState('बंद');

    const [khsalenew, setkhSalenew] = useState('चालू');
    const [khsaleedit, setKhsaleedit] = useState('बंद');
    const [khsaledelete, setKhsaledelete] = useState('बंद');


    const [advnew, setAdvnew] = useState('चालू');
    const [advedit, setAdvedit] = useState('बंद');
    const [advdelete, setAdvdelete] = useState('बंद');

    const [ratibnew, setRatibnew] = useState('चालू');
    const [ratibedit, setRatibedit] = useState('बंद');
    const [ratibdelete, setRatibdelete] = useState('बंद');

    const [morningFrom, setMorningFrom] = useState('05:00');  // 5:00 AM
    const [morningTo, setMorningTo] = useState('12:00');      // 12:00 PM
    const [eveningFrom, setEveningFrom] = useState('17:00');  // 5:00 PM
    const [eveningTo, setEveningTo] = useState('23:00');      // 11:00 PM

    const [autosnf, setAutosnf] = useState('चालू');

    // Function to handle time input changes
    const handleTimeChange = (setter) => (event) => {
        setter(event.target.value);
    };

    // Convert time to 12-hour format
    const convertTo12Hour = (time) => {
        if (!time) return '';
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${period}`;
    };

    function handleEnterKey(event, currentIndex) {

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

    }


    useEffect(()=>{
        console.log(localStorage.getItem("suchana"));
        setInput(prevInput => ({
            ...prevInput,
            suchana: localStorage.getItem("suchana")
        }));
    },[])



    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            updatesuchana();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[4]) {
                    inputRefs.current[4].focus();
                }
            }
        }
    }



    async function updatesuchana() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/updatesuchana.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2,suchana: input.suchana  }).then(function sample(response) {

            if (response.data) {
               if(response.data === "updated!")
               {
                notify("बिल प्रिंट सूचना अपडेट झाली!");
                localStorage.setItem("suchana",input.suchana);

               }
            }
        }).catch(err => {
            console.log(err);
        });
    }

 



    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    return (
        <div className="khadyamain">
            <div className="firstrowkhadya">
                <img onClick={props.visibility} className='back' src={back} alt="" />
                <div className="colmainkhadya">
                    <div className="firstrow2">
                        <div className="rowsank">
                            <div className="mb-2">
                                <span className='subheadsank'>सेटिंग्ज</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='linekh' />
            <div className="row1kh">
            </div>
            <div className="row1mobile">
                <div className="mb-2">
                    <span className='subheadsank'>बिल प्रिंट सुचना</span>
                    <input type="text" onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} className={"form-control"} autoComplete='off' id="suchana" name="suchana" value={input.suchana} onChange={handleChange} ></input>
                </div>
            </div>
            <div className='linekh' />

            <div className="row1kh">
                <div className="mb-2">
                    <button onClick={updatesuchana} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[3] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                        अपडेट सेटिंग्ज
                    </button>
                    
                </div>
            </div>
          
            <ToastContainer />
        </div>
    )
}

export default Settings
