import React, { useContext, useRef, useState,useEffect } from 'react'
import './Extracontents.css'
import Anydesk from "../../imgs/anydesk.png";
import khate from "../../imgs/khate.webp";
import Teamviewer from "../../imgs/Teamviewer.png";
import App from "../../imgs/app.webp";
import Password from "../../imgs/password.png";
import Mobileuser from "../../imgs/mobileuser.png";
import Settings2 from "../../imgs/settings.png";
import pregister from "../../imgs/pregister.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Globalcontext from '../../Context/Globalcontext';
import MobileUserr from '../Mobileuser/Mobileuser';
import Settings from '../Settings2/Settings2'

const Extracontents = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    // useEffect(() => {
       
    //         setShowBackImage(true);
    //         setMastermenu(false);
    //         setTransitionEnded(true);
        
    // }, [])

    const areUSure = (choose) => {

        handleDialog("", false);
    };

    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const visibility2 = () => {
        localStorage.setItem("glcustsr", "");
    

        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    return (
        <>
            <div className='line' />
            <div className="maincon">

                <div className="rowreport">
                    <div className="mainconreport">
                        <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>डाउनलोड लिंक्स व इतर</span>
                        <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
                            <Box setsubmenu={setsubmenu} visibility={visibility2} background={"linear-gradient(179deg, var(--t1dark), var(--t1dark))"} head={"Anydesk"} img={<img src={Anydesk} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility2} background={"linear-gradient(179deg, var(--t6dark), var(--t6dark))"} head={"Teamviewer"} img={<img src={Teamviewer} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility2} background={"linear-gradient(179deg, var(--t5dark), var(--t5dark))"} head={"Mobile App Seetings"} img={<img src={App} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility2} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"Change Password"} img={<img src={Password} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t4dark), var(--t4dark))"} head={"Settings"} img={<img src={Settings2} alt="" />} />
                            <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3dark), var(--t3dark))"} head={"Mobile User"} img={<img src={Mobileuser} alt="" />} />
                        </div>
                    </div>
                </div>

                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">
                    </div>
                    <div className="second">
                        <div className='line' />
                        <span className='firstspan'>1. ही दोन्ही सॉफ्टवेअर्स आपणास ऑनलाइन सर्विस साठी उपयुक्त आहेत.</span>
                        <span className='infospan'>2. ही दोन्ही सॉफ्टवेअर्स पूर्णपणे मोफत आहेत.</span>
                        <span className='infospan'>3. आपण यापैकी एक सॉफ्टवेअर ओपन करुन iDairy बाबत कोणतीही ऑनलाइन मदत मिळवू शकता.</span>
                        <span className='infospan'>4. मोबाईल ऍप सेटिंग मधून मोबाईल ऍप बंद किंवा चालू करू शकता.</span>
                    </div>
                </div>

              
                <div className={`cust ${mastermenu ? 'hidden' : ''}`}>
                    {transitionEnded && showBackImage && (
                      
                        submenu === "mobileuser" ? <MobileUserr setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                        submenu === "settings" ? <Settings setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                        ""
                        
                    )}
                </div>
                
            </div>
          
            {dialog.isLoading && (
                <Dialog
                    //Update
                    submessage={dialog.nameProduct}
                    onDialog={areUSure}
                    message={dialog.message}
                    functionname={openkendra}
                    button1text="नाही"
                    button2text="होय"
                />
            )}
        </>
    )
}

export default Extracontents
