import React, { useEffect, useRef, useState } from 'react'
import './Khsale.css'
import back from "../../imgs/backexam.png";
import date from "../../imgs/bdate.png";
import khadya from "../../imgs/khadya.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilTrash, UilSetting } from '@iconscout/react-unicons'
import { useLocation, useNavigate } from "react-router-dom";
import Globalcontext from '../../Context/Globalcontext';

const Khsale = (props) => {
    const inputRefs = useRef([]);
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ date: new Date().toISOString().substr(0, 10), date1: "", date2: new Date().toISOString().substr(0, 10), kno: "", name: "", khname: "", khcompany: "", quin: "", rate: "", amt: "", tquin: "", tamt: "" });
    const [dates, setDates] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10)});
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [khnames, setKhnames] = useState([]);
    const [company, setCompany] = useState([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [stock, setStock] = useState();
    const [sredit, setSredit] = useState('');
    const [srdelete, setSrdelete] = useState('');
    const [stockcompany, setStockcompany] = useState();
    const [wait, setWait] = useState(false);
    const refprint2 = useRef(null);
    const refprint2close = useRef(null);
    const refdelete = useRef(null);
    const refdeleteclose = useRef(null);
    const today = new Date().toISOString().split('T')[0]; 


    function handleEnterKey(event, currentIndex) {
        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }
    }

    const handleprintclose = () => {
        refprint2close.current.click();
    }

    const handlePrint = () => {
       
        refprint2.current.click();
    }


    const handleDeleteclose = () => {
        setSrdelete("");
        refdeleteclose.current.click();
        
    }

    const handleDelete = (sr) => {
        setSrdelete(sr);
        refdelete.current.click();
    }


    const getstock = async () => {
        if (input.khname) {
            khnames.map((user, index) =>
                user.khname === input.khname ?
                    setStock(user.current_stock)
                    : ""
            )
        }
        else {
            setStock("");
            setStockcompany("");
        }
        getkhcompany();
    }

    useEffect(() => {
        setStock("");
        setStockcompany("");
        setCompany([]);
        getkhcompany();
    }, [input.khname])


    const getstockcompany = async () => {
        if (input.khcompany) {
            company.map((user, index) =>
                user.khcompany === input.khcompany && user.khcompany !== "-" ?
                    setStockcompany(user.current_stock)
                    : ""
            )
        }
        getkhcompany();
    }

    async function getkhcompany() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        setTimeout(() => {
            props.setProgress(70);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhcompany.php', { khname: input.khname, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setCompany(response.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).replace(/ /g, '-');  // Replacing spaces with hyphens
    };


    useEffect(() => {

        if (input.kno === "") {
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                khname: "",
                quin: "",
                rate: "",
                amt: "",
            }))

            // setNewedit("new");
        }
        else {
            //getcustdata(input.kno);
            let custlisttemp = JSON.parse(localStorage.getItem("custlist"));
            for (let i = 0; i < custlisttemp.length; i++) {
                setInput(prevInput => ({
                    ...prevInput,
                    name: ''
                }));
                const user = custlisttemp[i];
                if (user.kno === input.kno) {
                    setInput(prevInput => ({
                        ...prevInput,
                        name: user.cname
                    }));
                    break; // Exit the loop once the condition is met
                }
            }

        }
    }, [input.kno])

    useEffect(() => {
        if (input.rate && input.rate !== "" && input.quin && input.quin !== "") {
            let amt = parseFloat(parseFloat(input.rate) * parseFloat(input.quin)).toFixed(2);
            setInput(prevInput => ({
                ...prevInput,
                amt: amt,
            }))
        }
    }, [input.rate, input.quin])



    const savekhadyasale = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.date === "" || input.kno === "" || input.name === "" || input.khname === "खाद्याचे नाव निवडा" || input.khname === "" || input.quin === "" || input.rate === "" || input.amt === "") {
            notifyerror("अपूर्ण एंट्री माहिती!");
            return;
        }
        let rawData = {
            date: input.date,
            kno: input.kno,
            khname: input.khname,
            khcompany: input.khcompany,
            quin: input.quin,
            rate: input.rate,
            amt: input.amt,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: newedit,
            sredit: sredit,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        if (newedit === "edit") {
            formData.append('data', rawData)
        }
        else if (newedit === "new") {
            formData.append('data', rawData)
        }
        axios.post('https://idairy.co.in/dairy/php/cust/savekhsale.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {

            if (newedit === "new") {
                if (response.data.trim() === "Saved.") {
                    notify("एंट्री सेव्ह झाली!");
                    setWait(false);
                }
            }
            else {
                if (response.data.trim() === "updated.") {
                    notify("एंट्री अपडेट झाली!")
                    setWait(false);
                }
            }
            setInput(prevInput => ({
                ...prevInput,
                kno: "",
                name: "",
                khname: "",
                khcompany: "",
                quin: "",
                rate: "",
                amt: "",
            }))
            setSredit("");
            setNewedit("new");
            getkhsale(dates.date1, dates.date2);
            if (inputRefs.current[1]) {
                inputRefs.current[1].focus();
            }

        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    const handleedit = (sr, date, kno, name, khname, khcompany, quin, rate, amt) => {

        //setInput({ sr: sr, date: date,date1:"",date2:"",kno: kno, name: name, khname: khname, khcompany: khcompany, quin: quin, rate: rate, amt: amt,tquin: "", tamt: "" })
        setInput(prevInput => ({
            ...prevInput,
            sr: sr, date: date, kno: kno, name: name, khname: khname, khcompany: khcompany, quin: quin, rate: rate, amt: amt
        }))
        setNewedit("edit");
        setSredit(sr);
    }

    async function deletesanklan() {
        handleDeleteclose();
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/deletekhsale.php', { sr: srdelete, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                notify(response.data);
                setInput(prevInput => ({
                    ...prevInput,
                    kno: "",
                    name: "",
                    khname: "",
                    quin: "",
                    rate: "",
                    amt: "",
                }))
                setSredit("");
                setNewedit("new");
                setSrdelete("");
                getkhsale(dates.date1, dates.date2);
                if (inputRefs.current[1]) {
                    inputRefs.current[1].focus();
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getkhname() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhname.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {

                setKhnames(response.data);
            }

        }).catch(err => {
            console.log(err);
        });
    }

    function handleEnterKeybutton(event, currentIndex) {
        if (event.key === 'Enter') {
            event.preventDefault();
            savekhadyasale();
            let nextIndex = null;
            if (currentIndex === 0) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
        else if (event.key === "Backspace") {
            if (event.target.tagName === "BUTTON") {
                if (inputRefs.current[4]) {
                    inputRefs.current[4
                    ].focus();
                }
            }
        }
    }

    useEffect(() => {
        if (inputRefs.current[1]) {
            inputRefs.current[1].focus();
        }

        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (dayOfMonth >= 1 && dayOfMonth <= 10) {
            firstDayOfMonth = 1;
            lastDayOfMonth = 10;
        } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
            firstDayOfMonth = 11;
            lastDayOfMonth = 20;
        } else if (dayOfMonth >= 21 && (dayOfMonth <= 30 || dayOfMonth <= 31)) {
            firstDayOfMonth = 21;
            lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
        }

        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
            getkhsale(formattedDate1, formattedDate2);
        }
    }, [])


    async function getkhsale2(date1, date2) {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhsaledate.php', { date1: date1, date2: date2, date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data.length >0) {
                setDataSource(response.data);
                setInput(value => ({ ...value, tquin: response.data[0].tquin, tamt: response.data[0].tamt }));
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
                handleprintclose();

            }
            else{
                setDataSource([]);
                setInput(value => ({ ...value, tquin:"0", tamt:"0"}));
                handleprintclose();
            }

        }).catch(err => {
            console.log(err);
            handleprintclose();
        });
    }


    async function getkhsale(date1, date2) {
        console.log("in");
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getkhsaledate.php', { date1: date1, date2: date2, date: input.date, time: input.time, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
             console.log(response.data);
            if (response.data.length >0) {
                setDataSource(response.data);
                setInput(value => ({ ...value, tquin: response.data[0].tquin, tamt: response.data[0].tamt }));
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
            else{
                setDataSource([]);
                setInput(value => ({ ...value, tquin:"0", tamt:"0"}));
            }

        }).catch(err => {
            console.log(err);
        });

        getkhname();
    }

    const setNewPeriod = async (event) => {
        getkhsale2(dates.date1,dates.date2)
        
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));

        if (name === 'date') {
            // If the value is blank or invalid, reset to the last valid date or today
            if (value === "" || value === "dd/mm/yyyy" || value > today) {
              setInput(prevInput => ({
                ...prevInput,
                date: prevInput.date && prevInput.date <= today ? prevInput.date : today
              }));
              return;
            }
          }
    }

    const handleChangedates = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setDates(value => ({ ...value, [name]: valuecurrent }));
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };


    useEffect(() => {
        const currentDate1 = new Date(dates.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
            lastDayOfMonth1 = 10;
        } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
            lastDayOfMonth1 = 20;
        } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
            lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setDates(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
    }, [dates.date1]);

    return (
        <>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint2}
                data-bs-target="#printinput2"
            >
            </button>

            <div className="modal fade" id="printinput2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprint2close} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">कालावधी निवडा</h5>

                            <div className="rowbill">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={dates.date1} onChange={handleChangedates} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={dates.date2} onChange={handleChangedates} ></input>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={setNewPeriod} >ओके</button>
                        </div>
                    </div>
                </div>
            </div>


            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refdelete}
                data-bs-target="#deletekhsale"
            >
            </button>

            <div className="modal fade" id="deletekhsale" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">खाद्य एंट्री डिलीट करायची?</h5>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDeleteclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={deletesanklan} >होय</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="khadyamain">
                <div className="firstrowkhadya">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmainkhadya">
                        <div className="firstrow2">
                            <div className="row1kh100">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className={newedit === "new" ? "form-control inputfont" : "form-control inputfont editmode"} autoComplete='off' id="date" name="date" value={input.date} onChange={handleChange} ></input>
                                </div>
                                <div onClick={handlePrint} className="rightkhsaletop">
                                    <img className='khadyaicon' src={date} alt="" />
                                    <span className='khsaleblack'>कालावधी :<span className='khsaleblue'>{formatDate(dates.date1) + " ते " + formatDate(dates.date2)}</span> </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='linekh' />
                <div className="row1khsale">
                    <div className="mb-2">
                        <span className='subheadsank'>खाते क्रमांक</span>
                        <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="number" className={newedit === "new" ? "form-control inputfontkh" : "form-control inputfontkh editmode"} autoComplete='off' id="kno" name="kno" value={input.kno} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2">
                        <span className='subheadsank'>सभासदाचे नाव</span>
                        <input type="text" disabled className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="name" name="name" value={input.name} onChange={handleChange} ></input>
                    </div>
                </div>
                <div className="row1khsale">
                    <div className="mb-2">
                        <span className='subheadsank'>खाद्य नाव{stock && (
                            <span className='lblredkh'> (शिल्लक स्टॉक : {stock})</span>
                        )}</span>
                        <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="khname" name="khname" value={input.khname} onChange={handleChange} onBlur={getstock}>
                            <option key={0} value={"खाद्याचे नाव निवडा"}>खाद्याचे नाव निवडा</option>
                            {khnames ? khnames.map((opt, index) =>

                                <option key={index + 1} value={opt.khname}>{opt.khname}</option>

                            ) : ""}

                        </select>
                    </div>

                    <div className="mb-2">
                        <span className='subheadsank'>खाद्य कंपनी{stockcompany && (
                            <span className='lblredkh'> (शिल्लक स्टॉक : {stockcompany})</span>
                        )}</span>

                        <select onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="khcompany" name="khcompany" value={input.khcompany} onChange={handleChange} onBlur={getstockcompany}>
                            <option key={0} value={"खाद्य कंपनी निवडा"}>खाद्य कंपनी निवडा</option>
                            {company &&
                                // Remove duplicates by creating a Set and then spreading it back into an array
                                [...new Set(company.map(opt => opt.khcompany))].map((uniqueCompany, index) => (
                                    <option key={index} value={uniqueCompany}>{uniqueCompany}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div className="mb-2 kkh">
                        <span className='subheadsank'>विक्री नग</span>
                        <input onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="quin" name="quin" value={input.quin} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2 kkh">
                        <span className='subheadsank'>दर</span>
                        <input onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)} type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="rate" name="rate" value={input.rate} onChange={handleChange} ></input>
                    </div>
                    <div className="mb-2 kkh2">
                        <span className='subheadsank'>एकूण</span>
                        <input disabled type="number" className={newedit === "new" ? "form-control" : "form-control editmode"} autoComplete='off' id="amt" name="amt" value={input.amt} onChange={handleChange} ></input>
                    </div>

                </div>

                <div className="mb-2">
                    <div className="row1kh">
                        <div className="row1khsale">
                            {
                                wait ?
                                    <button type="submit" className="btn btn-primary mx-1 sanksmall">
                                        {
                                            "लोडिंग.."
                                        }
                                    </button>
                                    :
                                    <button onClick={savekhadyasale} onKeyDown={e => handleEnterKeybutton(e, 0)} ref={ref => (inputRefs.current[6] = ref)} type="submit" className="btn btn-primary mx-1 sanksmall">
                                        {newedit === "new" ?
                                            "सेव्ह" :
                                            "अपडेट"
                                        }
                                    </button>
                            }
                            <button type="submit" className="btn btn-primary mx-1 sanksmall">विक्री यादी</button>
                        </div>
                        <div className="rightkhsale">
                            <img className='khadyaicon' src={khadya} alt="" />
                            <span className='khsaleblack'>एकूण विक्री नग : <span className='khsalered'>{input.tquin}</span> </span>
                            <span className='khsaleblack2'>|</span>
                            <span className='khsaleblack2'>रक्कम : <span className='khsalered'>₹{input.tamt}</span> </span>
                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerkhsale">
                        <table id="myTable" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmall' scope="col">क्र.</th>
                                    <th className='thsmall' scope="col">दिनांक</th>
                                    <th className='thsmall' scope="col">वेळ</th>
                                    <th className='thsmall' scope="col">खाते क्र</th>
                                    <th className='thlarge' scope="col">नाव</th>
                                    <th className='thsmall' scope="col">खाद्य</th>
                                    <th className='thsmall' scope="col">नग</th>
                                    <th className='thsmall' scope="col">दर</th>
                                    <th className='thsmall' scope="col">एकूण</th>
                                    <th className='thsmall' scope="col">एक्शन</th>
                                </tr>
                            </thead>
                            <tbody>
                                {value.length > 0 ? tableFilter.map((user, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{user.date}</td>
                                        <td>{user.timee}</td>
                                        <td>{user.kno}</td>
                                        <td>{user.name}</td>
                                        <td>{user.khname}</td>
                                        {/* <td>{user.khcompany}</td> */}
                                        <td>{user.quin}</td>
                                        <td>{user.rate}</td>
                                        <td>{user.amt}</td>

                                        <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.khcompany, user.quin, user.rate, user.amt) }} className="icsank" /><UilTrash onClick={() => { handleDelete(user.sr) }} v className="icsank" /></td>
                                    </tr>
                                )
                                    :
                                    DataSource.map((user, index) =>
                                        <tr key={index}>
                                            <td className='thsmallkh'>{index + 1}</td>
                                            <td className='thsmallkh2'>{formatDate(user.date)}</td>
                                            <td className='thsmallkh'>{user.timee}</td>
                                            <td className='thsmallkh'>{user.kno}</td>
                                            <td className='thsmallkh3'>{user.name}</td>
                                            <td className='thsmallkh3'>{user.khname}</td>
                                            {/* <td className='thsmallkh'>{user.khcompany}</td> */}
                                            <td className='thsmallkh'>{user.quin}</td>
                                            <td className='thsmallkh'>{user.rate}</td>
                                            <td className='thsmallkh'>{user.amt}</td>
                                            <td><UilEditAlt onClick={() => { handleedit(user.sr, user.date, user.kno, user.name, user.khname, user.khcompany, user.quin, user.rate, user.amt) }} className="icsank" /></td>  
                                            {/* <UilTrash onClick={() => { handleDelete(user.sr) }} v className="icsank" /> */}

                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default Khsale
