
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Sidebar from './componants/Sidebar/Sidebar';
import { useContext, useState,useEffect} from 'react';
import Globalcontext from './Context/Globalcontext';
import Globalstates from './Context/Globalstates';
import LoadingBar from 'react-top-loading-bar'
import  Login  from './componants/Login/Login';
import Sanklan from './componants/Sanklan/Sanklan';
import Khpur from './componants/Khpur/Khpur';
import Khsale from './componants/Khsale/Khsale';
import Uchal from './componants/Uchal/Uchal';
import Billprint from './componants/Billprint/Billprint';
import Pregisterprint from './componants/Pregisterprint/Pregisterprint';
import Pregisterprintvar from './componants/Pregisterprintvar/Pregisterprintvar';
import Other from './componants/Other/Other';
import Customerreport from './componants/Customerreport/Customerreport';
import Customerreportprint from './componants/Customerreportprint/Customerreportprint';
import Customerreportprintvar from './componants/Customerreportprintvar/Customerreportprintvar';
import Sanklanreport from './componants/Sanklanreport/Sanklanreport';
import Sanklanreportprint from './componants/Sanklanreportprint/Sanklanreportprint';
import Khadyaadvreportprint from './componants/Khadyaadvreportprint/Khadyaadvreportprint';
import Thevvatapprint from './componants/Thevatapprint/Thevvatapprint';
import Bankreportprint from './componants/Bankreportprint/Bankreportprint';
import Kapatprint from './componants/Kapatprint/Kapatprint';
import Bonusprint from './componants/Bonusprint/Bonusprint';
import Extra from './componants/Extra/Extra';

import { Help } from '@mui/icons-material';
import InternetStatusAlert from './componants/InternetStatusAlert/InternetStatusAlert';
import Billprint2 from './componants/Billprint2/Billprint2';
import Billprint2Change from './componants/Billprint2/Billprint2change';
import BillprintTest from './componants/BillprintTest/BillprintTest';
import Billprint30 from './componants/Billprint30/Billprint30';
import Billprintvar from './componants/Billprintvar/Billprintvar';
import Bill30view from './componants/Bill30view/Bill30view';
import Custlistprint from './componants/Custlistprint/Custlistprint';
import Vikrireportprint from './componants/Vikrireportprint.jsx/Vikrireportprint';



function App() {
  const [progress, setProgress] = useState(0);
  const a = useContext(Globalcontext);
 

  return (
    <Globalstates>
      <Router>
       <div className="App">
       <InternetStatusAlert/> {/* Include the InternetStatusAlert here */}
       <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress()}
        />
          <Routes>
            <Route exact path="/" element={<Login setProgress={setProgress}/>}/>
            <Route exact path="/idairygrow" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/Dashboard" element={<Sidebar setProgress={setProgress} isLoggedIn setIsLoggedIn/>}/>
            <Route exact path="/Transections" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/other" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/reports" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/extra" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/help" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/regi" element={<Sidebar setProgress={setProgress}/>}/>
            <Route exact path="/sanklan" element={<Sanklan setProgress={setProgress}/>}/>
            <Route exact path="/khpur" element={<Khpur setProgress={setProgress}/>}/>
            <Route exact path="/khsale" element={<Khsale setProgress={setProgress}/>}/>
            <Route exact path="/uchal" element={<Uchal setProgress={setProgress}/>}/>
            <Route exact path="/bill30view" element={<Bill30view setProgress={setProgress}/>}/>
            <Route exact path="/billprint" element={<Billprint setProgress={setProgress}/>}/>
            <Route exact path="/billprint2" element={<Billprint2 setProgress={setProgress}/>}/>
            <Route exact path="/billprint2change" element={<Billprint2Change setProgress={setProgress}/>}/>
            <Route exact path="/billprinttest" element={<BillprintTest setProgress={setProgress}/>}/>
            <Route exact path="/billprintvar" element={<Billprintvar setProgress={setProgress}/>}/>
            <Route exact path="/billprint30" element={<Billprint30 setProgress={setProgress}/>}/>
            <Route exact path="/pregisterprint" element={<Pregisterprint setProgress={setProgress}/>}/>
            <Route exact path="/bankreportprint" element={<Bankreportprint setProgress={setProgress}/>}/>
            <Route exact path="/pregisterprintvar" element={<Pregisterprintvar setProgress={setProgress}/>}/>
            <Route exact path="/custreportprint" element={<Customerreportprint setProgress={setProgress}/>}/>
            <Route exact path="/custreportprintvar" element={<Customerreportprintvar setProgress={setProgress}/>}/>
            <Route exact path="/sanklanreportprint" element={<Sanklanreportprint setProgress={setProgress}/>}/>
            <Route exact path="/vikrireportprint" element={<Vikrireportprint setProgress={setProgress}/>}/>
            <Route exact path="/khadyaadvreportprint" element={<Khadyaadvreportprint setProgress={setProgress}/>}/>
            <Route exact path="/kapatprint" element={<Kapatprint setProgress={setProgress}/>}/>
            <Route exact path="/thevvatapprint" element={<Thevvatapprint setProgress={setProgress}/>}/>
            <Route exact path="/bonusprint" element={<Bonusprint setProgress={setProgress}/>}/>
            <Route exact path="/custlistprint" element={<Custlistprint setProgress={setProgress}/>}/>
        
          </Routes>

        </div>
       
      </Router>
    </Globalstates>
  );
}

export default App;
