import React, { useEffect, useRef, useState } from 'react'
import './Pregister.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import close from "../../imgs/close.png";
import { Co2Sharp, Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Billprint from '../Billprint/Billprint';
import { useNavigate } from 'react-router-dom';
import Status from "../../imgs/statusmob.jpeg";

const Pregister = (props) => {
    const [input, setInput] = useState({ date1: "", date2: new Date().toISOString().substr(0, 10), knofrom: "", knoto: "", mobileuser: "" });
    const inputRefs = useRef([]);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [print, setprint] = useState(false);
    const [showitr, setShowitr] = useState("No");
    const cellRefs = useRef([]);
    const [value, setValue] = useState('');
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();
    const refprint = useRef(null);
    const refprintclose = useRef(null);
    const [rtype, setRtype] = useState("1");
    const [showother, setShowother] = useState("Yes");
    const [wait, setWait] = useState(false);
    const [mobileusers, setMobileusers] = useState([]);


    const handlertype = () => {
        if (rtype === "1") {
            setRtype("2");
        }
        else {
            setRtype("1");
        }
    }

    const handleprintclose = () => {
        refprintclose.current.click();
    }

    const handlePrint = () => {
        refprint.current.click();
    }

    const handleOtheractive = (event) => {
        var checked = event.target.checked;

        if (event.target.checked) {
            setShowother("Yes");
        }
        else {
            setShowother("No");
        }
    }

    async function getmobileusers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getmobileusers.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setMobileusers(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        const currentDate = new Date();
        const dayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let firstDayOfMonth;
        let lastDayOfMonth;

        if (dayOfMonth >= 1 && dayOfMonth <= 10) {
            firstDayOfMonth = 1;
            lastDayOfMonth = 10;
        } else if (dayOfMonth >= 11 && dayOfMonth <= 20) {
            firstDayOfMonth = 11;
            lastDayOfMonth = 20;
        } else if (dayOfMonth >= 21 && dayOfMonth <= 30) {
            firstDayOfMonth = 21;
            lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of current month
        }

        if (firstDayOfMonth !== undefined && lastDayOfMonth !== undefined) {
            const formattedDate1 = new Date(currentYear, currentMonth, firstDayOfMonth).toLocaleDateString('en-CA');
            const formattedDate2 = new Date(currentYear, currentMonth, lastDayOfMonth).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date1: formattedDate1,
                date2: formattedDate2
            }));
        }

        getmobileusers();

        getmaxno();


    }, []);

    async function getmaxno() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getmaxno.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data) {
                setInput(prevInput => ({
                    ...prevInput,
                    knofrom: "1",
                    knoto: response.data,
                }))
            }
            else {
                setInput(prevInput => ({
                    ...prevInput,
                    knofrom: "0",
                    knoto: "0"
                }))
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        const currentDate1 = new Date(input.date1);
        const dayOfMonth1 = currentDate1.getDate();
        const currentMonth1 = currentDate1.getMonth();
        const currentYear1 = currentDate1.getFullYear();

        let lastDayOfMonth1;

        if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
            lastDayOfMonth1 = 10;
        } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
            lastDayOfMonth1 = 20;
        } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
            lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
        }

        if (lastDayOfMonth1 !== undefined) {
            const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
            setInput(prevInput => ({
                ...prevInput,
                date2: formattedDate2
            }));
        }
        setDataSource([]);
        setTableFilter([]);
    }, [input.date1]);

    const getpregister = () => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.knofrom === "" || input.knoto === "") {
            notifyerror("सभासद क्रमांक भरा");
            return;
        }
        let rawData = {
            date1: input.date1,
            date2: input.date2,
            knofrom: input.knofrom,
            knoto: input.knoto,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post('https://idairy.co.in/dairy/php/cust/getpregister.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            // console.log(response.data);
            if (response.data && response.data.length > 0) {
                setDataSource(response.data);
            }
            else {
                notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
            }
            setWait(false);
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }

    const showprint = () => {

        if (rtype === '1') {
            localStorage.setItem("pregister1", "1");
        }
        else if (rtype === '2') {
            localStorage.setItem("pregister1", "2");
        }

        if (DataSource && DataSource.length > 0) {
            console.log(showitr);
            localStorage.setItem("date1print", input.date1);
            localStorage.setItem("date2print", input.date2);
            localStorage.setItem("mobileuser", input.mobileuser);
            localStorage.setItem("showitr", showitr);
            const url = "/pregisterprint";
            window.open(url, '_blank');
        }
        else {
            notifyerror("या कालावधीचे रजिस्टर उपलब्ध नाही!");
        }
    }

    const handleCellKeyPress = (e, rowIndex) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (rowIndex + 1 < cellRefs.current.length) {
                const nextRow = cellRefs.current[rowIndex + 1];
                nextRow[0].focus();
            }
            e.target.blur(); // Remove focus from the cell
        } else if (isNaN(e.key) && e.key !== 'Backspace') {
            e.preventDefault(); // Prevent non-numeric input
        }
    };

    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            if (currentIndex === 2) {
                if (input.name !== "") {
                    nextIndex = currentIndex + 1;
                }
                else {
                    nextIndex = currentIndex;
                }
            }
            else {
                nextIndex = currentIndex + 1;
            }
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }));
    }

    const handleitr = (event) => {
        var checked = event.target.checked;

        if (event.target.checked) {
            setShowitr("Yes");
        }
        else {
            setShowitr("No");
        }
    }


    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">प्रिंट डिझाइन निवडा</h5>

                            <div className="radiotheme">
                                <div className="form-check">
                                    <input onClick={handlertype} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                                    <label className="form-check-label radiolabel" htmlFor="radio2">
                                        रजिस्टर प्रिंट डिझाईन 1
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={handlertype} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                                    <label className="form-check-label radiolabel" htmlFor="radio1">
                                        रजिस्टर प्रिंट डिझाईन 2
                                    </label>
                                </div>
                            </div>

                            <div className="mb-2">
                                <span className='subheadsank'>मोबाईल यूजर नाव</span>
                                <select onKeyDown={e => handleEnterKey(e, 6)} ref={ref => (inputRefs.current[6] = ref)} className={"form-control"} autoComplete='off' id="mobileuser" name="mobileuser" value={input.mobileuser} onChange={handleChange}>
                                    <option key={0} value={"मोबाईल यूजर नाव निवडा"}>मोबाईल यूजर नाव निवडा</option>
                                    {mobileusers ? mobileusers.map((opt2, index) =>

                                        <option key={index + 1} value={opt2.name}>{opt2.name}</option>

                                    ) : ""}
                                </select>
                            </div>

                            <span className='note'>प्रिंट सेटिंग्ज</span>

                            <div className="form-check check">
                                <input className="form-check-input" type="checkbox" value="" onChange={handleitr} id="activethev" />
                                <span className="checklbl" htmlFor="activeother">
                                    इतर कपात कॉलम्स
                                </span>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={showprint}>ओके</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sanklanmain">
                <div className="firstrowsank">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <div className="colmain">
                        <div className="firstrow2">
                            <div className="rowbill">
                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>दिनांक</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill se'> || </span>

                                <div className="mb-2 se">
                                    <span className='subheadsank'>खाते क्र.</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} type="text" className="form-control" autoComplete='off' id="knofrom" name="knofrom" value={input.knofrom} onChange={handleChange} ></input>
                                </div>

                                <span className='subheadbill'>ते</span>

                                <div className="mb-2">
                                    <span className='subheadsank'>खाते क्र</span>
                                    <input disabled onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)} type="text" className="form-control" autoComplete='off' id="knoto" name="knoto" value={input.knoto} onChange={handleChange} ></input>
                                </div>

                                <div className="mb-2">
                                    {

                                        wait ? (
                                            <button type="submit" id='show' className="btn btn-primary mx-1 sanksmall">लोडिंग..</button>
                                        ) : (
                                            <button onClick={getpregister} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रजिस्टर पहा</button>
                                        )

                                    }


                                    <button onClick={handlePrint} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">प्रिंट</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="firstrow2">
                    <div class="table-containerbill">
                        <table id="myTablebill" className="table table-bordered">
                            <thead>
                                <tr>
                                    <th className='thsmall' scope="col">खाते क्रमांक</th>
                                    <th className='thlarge' scope="col">नाव</th>
                                    <th className='thsmall' scope="col">म्हैस.लिटर</th>
                                    <th className='thsmall' scope="col">गाय.लिटर</th>
                                    <th className='thsmall' scope="col">ए.लिटर</th>
                                    <th className='thsmall' scope="col">ए.बील</th>
                                    <th className='thsmall' scope="col">मागील ठेव</th>
                                    <th className='thsmall' scope="col">ठेव कपात</th>
                                    <th className='thsmall' scope="col">एकूण ठेव</th>
                                    <th className='thsmall' scope="col">मागील एडव्हान्स बाकी</th>
                                    <th className='thsmall' scope="col">चालू एडव्हान्स</th>
                                    <th className='thsmall' scope="col">एकूण एडव्हान्स</th>
                                    <th className='thsmall' scope="col">एडव्हान्स कपात</th>
                                    <th className='thsmall' scope="col">नवीन एडव्हान्स बाकी</th>
                                    <th className='thsmall' scope="col">मागील खाद्य बाकी</th>
                                    <th className='thsmall' scope="col">चालू खाद्य</th>
                                    <th className='thsmall' scope="col">एकूण खाद्य</th>
                                    <th className='thsmall' scope="col">खाद्य कपात</th>
                                    <th className='thsmall' scope="col">नवीन खाद्य बाकी</th>
                                    <th className='thsmall' scope="col">इ.कपात/ली</th>
                                    <th className='thsmall' scope="col">इ.कपात/बील</th>
                                    <th className='thsmall' scope="col">वाहतूक</th>
                                    <th className='thsmall' scope="col">अदा</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DataSource && DataSource.map((user, index) =>
                                    <tr key={index}>
                                        <td className='thsmall'>{user.kno}</td>
                                        <td className='thsmall'>{user.cname}</td>
                                        <td className='thsmall2'>{parseFloat(user.mhliter).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.gayliter).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.tliter).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.billamt).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.magilthev).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.cthev).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.newthev).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.madv).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.cadv).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.tadv).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.advkapat).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.newadv).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.mkhadya).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.ckhadya).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.tkhadya).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.khadyakapat).toFixed(2)}</td>
                                        <td className='thsmall2'>{parseFloat(user.newkhadya).toFixed(2)}</td>
                                        <td className='thsmall2'>{user.ikapatliter ? parseFloat(user.ikapatliter).toFixed(2) : 0}</td>
                                        <td className='thsmall2'>{user.ikapatbill ? user.ikapatbill : 0}</td>
                                        <td className='thsmall2'>{user.vah ? parseFloat(user.vah) : 0}</td>
                                        <td className={(parseFloat(user.ada) || 0) > 0 ? 'thsmallgreen' : 'thsmallred'}>{parseFloat(user.ada) ? parseFloat(user.ada) : 0}
                                        </td>

                                    </tr>
                                )
                                }
                            </tbody>
                            <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                                <td className="thsmall" colSpan="2">एकूण</td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.mhliter), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.gayliter), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.mhliter) + parseFloat(user.gayliter), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.billamt), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.magilthev), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.cthev), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.newthev), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.madv), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.cadv), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.tadv), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.advkapat), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.newadv), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.mkhadya), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.ckhadya), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.tkhadya), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.khadyakapat), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.newkhadya), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.ikapatliter), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.ikapatbill), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + parseFloat(user.vah), 0).toFixed(2)}
                                </td>
                                <td className="thsmall2">
                                    {DataSource.reduce((total, user) => total + (parseFloat(user.ada) > 0 ? parseFloat(user.ada) : 0), 0).toFixed(2)}
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Pregister
