import React, { useContext, useEffect, useRef, useState } from 'react'
import './Sanklanreport.css'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";
import back from "../../imgs/backexam.png";
import { useNavigate } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { YoutubeSearchedForOutlined } from '@material-ui/icons';
import * as XLSX from 'xlsx';



const Sanklanreport = (props) => {
  const [input, setInput] = useState({ date1: new Date().toISOString().substr(0, 10), date2: new Date().toISOString().substr(0, 10), mobileuser: "", type: "",time: "" });
  const inputRefs = useRef([]);
  const [DataSource, setDataSource] = useState([]);
  const [DataSource2, setDataSource2] = useState([]);
  const [DataSource3, setDataSource3] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [print, setprint] = useState(false);
  const cellRefs = useRef([]);
  const [value, setValue] = useState('');
  const [mobileusers, setMobileusers] = useState([]);
  const notify = (msg) => toast(msg);
  const notifyerror = (msg) => toast.error(msg);
  const [customerData, setCustomerData] = useState([]);
  const navigate = useNavigate();

  const [rtype, setRtype] = useState("bydate");
  const handlertype = (value) => {
    getmobileusers();
    if (value === "all") {
      setRtype("all");
    }
    else if (value === "bydate") {
      setRtype("bydate");
    }
    else if (value === "anudan") {
      setRtype("anudan");
    }
    else if (value === "mobileuser") {
      setRtype("mobileuser");

    }
    else if (value === "ekmobileuser") {
      setRtype("ekmobileuser");

    }
  }

  useEffect(() => {
    const currentDate1 = new Date(input.date1);
    const dayOfMonth1 = currentDate1.getDate();
    const currentMonth1 = currentDate1.getMonth();
    const currentYear1 = currentDate1.getFullYear();

    let lastDayOfMonth1;

    if (dayOfMonth1 >= 1 && dayOfMonth1 <= 10) {
      lastDayOfMonth1 = 10;
    } else if (dayOfMonth1 >= 11 && dayOfMonth1 <= 20) {
      lastDayOfMonth1 = 20;
    } else if (dayOfMonth1 >= 21 && dayOfMonth1 <= 30) {
      lastDayOfMonth1 = new Date(currentYear1, currentMonth1 + 1, 0).getDate(); // Last day of selected month
    }

    if (lastDayOfMonth1 !== undefined) {
      const formattedDate2 = new Date(currentYear1, currentMonth1, lastDayOfMonth1).toLocaleDateString('en-CA');
      setInput(prevInput => ({
        ...prevInput,
        date2: formattedDate2
      }));
    }
    setDataSource([]);
    setTableFilter([]);
  }, [input.date1]);

  useEffect(() => {
    setDataSource([]);
  }, [rtype])

  async function getmobileusers() {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    axios.post('https://idairy.co.in/dairy/php/cust/getmobileusernames.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {
      if (response.data) {

        setMobileusers(response.data);
      }

    }).catch(err => {
      console.log(err);
    });
  }

  function handleEnterKey(event, currentIndex) {

    if (event.keyCode === 8) {
      if (event.target.name === "time") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          time: '',
        }));
      }
      else if (event.target.name === "type") {
        setInput((prevInputSettings) => ({
          ...prevInputSettings,
          type: '',
        }));
      }
    }

    if (event.key === 'Enter' || event.key === "Tab") {
      event.preventDefault();
      let nextIndex = null;
      if (currentIndex === 2) {
        if (input.name !== "") {
          nextIndex = currentIndex + 1;
        }
        else {
          nextIndex = currentIndex;
        }
      }
      else {
        nextIndex = currentIndex + 1;
      }
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }


  const exportToExcel = () => {
    const wb = XLSX.utils.table_to_book(document.getElementById('myTablebill'), { sheet: "Sheet JS" });
    const ws = wb.Sheets["Sheet JS"];

    // Set column widths for specific columns (adjust the width values as needed)
    ws['!cols'] = [
      { wch: 10 },  // Width of first column
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 40 },
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 9 },
      { wch: 10 },
      { wch: 20 },
      { wch: 13 },
      { wch: 20 },
      { wch: 14 },
      { wch: 14 },
      { wch: 17 },
    ];

    // Format large numbers as text to avoid scientific notation in Excel
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = ws[cellAddress];

        // Replace specific values
        if (cell && cell.v) {
          if (cell.v === 'म्हैस') cell.v = 'Buf';
          if (cell.v === 'गाय') cell.v = 'Cow';
          if (cell.v === 'सकाळ') cell.v = 'Mor';
          if (cell.v === 'संध्याकाळ') cell.v = 'Evn';
        }

        // Format large numbers as text
        if (cell && typeof cell.v === 'number' && String(cell.v).length >= 12) {
          cell.t = 's';  // Set the type to string
          cell.v = String(cell.v); // Convert the number to a string
        }
      }
    }

    // Set background color and font weight for the header row
    for (let C = 0; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (ws[cellAddress]) {
        ws[cellAddress].s = ws[cellAddress].s || {};
        ws[cellAddress].s.fill = {
          patternType: "solid",
          fgColor: { rgb: "ADD8E6" } // Light blue color
        };
        ws[cellAddress].s.font = { bold: true };
      }
    }

    XLSX.writeFile(wb, 'Sanklan Report (' + input.date1 + " ते " + input.date2 + ").xlsx");
};



  const filteData = (e) => {
    const searchValue = e.target.value.trim();

    if (searchValue !== "") {
        setValue(searchValue);
        const filtertable = DataSource.filter(o =>
            String(o.kno) === searchValue // Check for exact match with kno
        );

        setTableFilter([...filtertable]);
    } else {
        setValue(searchValue);
        setTableFilter([...DataSource]);
    }
  }
  const showprint = () => {
    if (DataSource && DataSource.length > 0) {
      localStorage.setItem("date1print", input.date1);
      localStorage.setItem("date2print", input.date2);
      localStorage.setItem("rtype", rtype);
      const url = "/sanklanreportprint";
      window.open(url, '_blank');
    }
    else {
      notifyerror("या कालावधीचे संकलन उपलब्ध नाही!");
    }
  }

  const showprintbydate = () => {
    if (DataSource2 && DataSource2.length > 0) {

      localStorage.setItem("date1print", input.date1);
      localStorage.setItem("date2print", input.date2);
      localStorage.setItem("rtype", rtype);
      const url = "/sanklanreportprint";
      window.open(url, '_blank');
    }
    else {
      notifyerror("या कालावधीचे संकलन उपलब्ध नाही!");
    }
  }

  const handleChange = async (event) => {
    const name = event.target.name;
    let valuecurrent = "";
    valuecurrent = event.target.value;
    setInput(value => ({ ...value, [name]: valuecurrent }));

    if (name === 'date2') {
      // Check if date2 is more than one year ahead of date1
      const date1 = new Date(input.date1);
      const date2 = new Date(valuecurrent);

      // Calculate the difference in days between date1 and date2
      const differenceInDays = (date2 - date1) / (1000 * 60 * 60 * 24);

      setInput((prevInput) => ({ ...prevInput, [name]: valuecurrent }));
      // if (differenceInDays <= 30) {

      // } else {
      //   notifyerror("रिपोर्ट लिमिट - 1 महिना");
      //   setInput((prevInput) => ({ ...prevInput, [name]: new Date().toISOString().substr(0, 10) }));
      // }
    }
  }

  const getreport = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      type: input.type,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getsanklanreport.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        setDataSource(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getreportbymobileuser = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mobileuser: input.mobileuser,
      time: input.time,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getsanklanreportbymobileuser2.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        setDataSource(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getreportbymobileuserek = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      type: input.type,
      mobileuser: input.mobileuser,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getsanklanreportbymobileuserek.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {

      if (response.data && response.data.length > 0) {
        setDataSource(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getanudan = () => {

    var mainuser2 = jsEncode.encode("mamapr", 125);

    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getanudan.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data && response.data.length > 0) {
        setDataSource3(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource3([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const getreportbydate = () => {
    var mainuser2 = jsEncode.encode("mamapr", 125);
    if (input.kno === "") {
      notifyerror("सभासद खाते क्रमांक भरा");
      setDataSource([]);
      return;
    }
    let rawData = {
      date1: input.date1,
      date2: input.date2,
      mainuser: localStorage.getItem("contact"),
      mainuser2: mainuser2,
    }
    rawData = JSON.stringify(rawData)
    let formData = new FormData()
    formData.append('data', rawData)
    axios.post('https://idairy.co.in/dairy/php/cust/getvadhtut.php', formData, {
      'Content-Type': 'multipart/form-data',

    }).then(function sample(response) {
      if (response.data && response.data.length > 0) {
        setDataSource2(response.data);
      }
      else {
        notifyerror("माहिती उपलब्ध नाही!");
        setDataSource2([]);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  function formatDate(inputDate) {
    // Ensure inputDate is in 'yyyy-mm-dd' format
    const parts = inputDate.split('-');
    if (parts.length !== 3) {
      return ''; // Invalid date format
    }

    // Convert month number to month name in English
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const year = parts[0];
    const month = parseInt(parts[1]) - 1; // Subtract 1 because months are 0-based
    const day = parts[2];

    return `${day}-${months[month]}`; //-${year}
  }

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  const filteDataold = (e) => {
    notify("in");
    if (e.target.value != "") {
        setValue(e.target.value);
        const filtertable = DataSource.filter(o => Object.keys(o).some(k =>
            String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        ));

        setTableFilter([...filtertable]);
    }
    else {
        setValue(e.target.value);
        setTableFilter([...DataSource]);
    }
}

  return (
    <div className="sanklanmain">
      <div className="firstrowsank">
        <img onClick={props.visibility} className='back' src={back} alt="" />
        <div className="colmain">
          <div className="firstrow2">
            <div className="rowbill">
              <div className="mb-2">
                <span className='subheadsank'>दिनांक</span>
                <input onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} type="date" className="form-control" autoComplete='off' id="date1" name="date1" value={input.date1} onChange={handleChange} ></input>
              </div>

              <span className='subheadbill'>ते</span>

              <div className="mb-2">
                <span className='subheadsank'>दिनांक</span>
                <input onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} type="date" className="form-control" autoComplete='off' id="date2" name="date2" value={input.date2} onChange={handleChange} ></input>
              </div>
              {
                rtype === "mobileuser" || rtype === "ekmobileuser" ?
                  <>
                    <div className='hori '>
                      <span className='subheadbill se'> || </span>
                      <div className="mb-2">
                        <span className='subheadsank'>मोबाईल युजर</span>
                        <select onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)} className={"form-control"} autoComplete='off' id="mobileuser" name="mobileuser" value={input.khname} onChange={handleChange}>
                          <option key={0} value={"युजरचे नाव निवडा"}>युजरचे नाव निवडा</option>
                          {mobileusers ? mobileusers.map((opt, index) =>

                            <option key={index + 1} value={opt.kno}>{opt.name}</option>

                          ) : ""}
                        </select>
                      </div>
                    </div>

                    {
                      rtype === "mobileuser" ?
                        <div className="mb-2">
                          <span className='subheadsank'>वेळ</span>
                          <select value={input.time} name='time' id='time' onChange={handleChange} className={"form-control"}>
                            <option key={0} value="प्रकार निवडा">वेळ निवडा</option>
                            <option key={1} value="सकाळ">सकाळ</option>
                            <option key={2} value="संध्याकाळ">संध्याकाळ</option>
                            <option key={3} value="दोन्ही">दोन्ही</option>
                          </select>
                        </div>
                        : ''
                    }

                    {
                      rtype === "ekmobileuser" ?
                        <div className="mb-2">
                          <span className='subheadsank'>प्रकार</span>
                          <select value={input.type} name='type' id='type' onChange={handleChange} className={"form-control"}>
                            <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                            <option key={1} value="म्हैस">म्हैस</option>
                            <option key={2} value="गाय">गाय</option>
                            <option key={3} value="दोन्ही">दोन्ही</option>
                          </select>
                        </div>
                        : ''
                    }
                  </>
                  : ''
              }

              <div className="mb-2">
                <button onClick={rtype === "all" ? getreport : rtype === "bydate" ? getreportbydate : rtype === "mobileuser" ? getreportbymobileuser : rtype === "ekmobileuser" ? getreportbymobileuserek : getanudan} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='show' className="btn btn-primary mx-1 sanksmall">रिपोर्ट पहा</button>
                <button onClick={rtype === "all" ? showprint : rtype === "bydate" ? showprintbydate : exportToExcel} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">{rtype === "anudan" ? "एक्सेल" : "प्रिंट"}</button>
                <button onClick={exportToExcel} ref={ref => (inputRefs.current[4] = ref)} type="submit" id='print' className="btn btn-primary mx-1 sanksmall">{"एक्सेल"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="firstrow2">
        <div class="table-containerbill">
          <div className="buttonsreportsank">
          <input type="text" className="form-control searchsank mx-3" id="searchsank" name="searchsank" placeholder={value === "all" || value === "bydate" ? 'शोधा' : 'खाते क्रमांक शोधा'} value={value} onChange={value === "all" || value === "bydate" ? filteDataold : filteData} autoComplete='off' />
            <div className="radio">
              <div className="form-check">
                <input onClick={() => { handlertype("bydate") }} defaultChecked className="form-check-input" type="radio" name="exampleRadios" id="radio2" value="option2" />
                <label className="form-check-label" htmlFor="radio2">
                  संकलन रिपोर्ट (वाढ-तूट पत्रक)
                </label>
              </div>
              <div className="form-check">
                <input onClick={() => { handlertype("all") }} className="form-check-input" type="radio" name="exampleRadios" id="radio1" value="option1" />
                <label className="form-check-label" htmlFor="radio1">
                  संकलन रिपोर्ट (एंट्रीज प्रमाणे)
                </label>
              </div>
            </div>
            <div className="radio">
              <div className="form-check">
                <input onClick={() => { handlertype("mobileuser") }} className="form-check-input" type="radio" name="exampleRadios" id="radio4" value="option4" />
                <label className="form-check-label" htmlFor="radio3">
                  मोबाईल यूजर रिपोर्ट
                </label>
              </div>
              <div className="form-check">
                <input onClick={() => { handlertype("ekmobileuser") }} className="form-check-input" type="radio" name="exampleRadios" id="radio5" value="option5" />
                <label className="form-check-label" htmlFor="radio3">
                  एकत्रित मोबाईल यूजर रिपोर्ट
                </label>
              </div>
            </div>
            <div className="radio">


              <div className="form-check">
                <input onClick={() => { handlertype("anudan") }} className="form-check-input" type="radio" name="exampleRadios" id="radio3" value="option3" />
                <label className="form-check-label" htmlFor="radio3">
                  अनुदान रिपोर्ट
                </label>
              </div>
              <div className="form-check">

              </div>
            </div>
          </div>
          {
            rtype === "all" ?
              <table id="myTablebill" className="table table-bordered">
                <thead>
                  <tr>
                    <th className='thsmall' scope="col">दिनांक</th>
                    <th className='thsmall' scope="col">खाते</th>
                    <th className='thsmall' scope="col">नाव</th>
                    <th className='thsmall' scope="col">वेळ</th>
                    <th className='thsmall' scope="col">प्रकार</th>
                    <th className='thsmall' scope="col">लिटर</th>
                    <th className='thsmall' scope="col">फॅट</th>
                    <th className='thsmall' scope="col">एसएनएफ</th>
                    <th className='thsmall' scope="col">दर</th>
                    <th className='thsmall' scope="col">रक्कम</th>
                  </tr>
                </thead>
                <tbody>
                  {value.length > 0 ? tableFilter.map((user, index) =>
                    <tr key={index}>
                      <td className='thsmallcust'>{formatDate(user.date)}</td>
                      <td className='thsmallcustlarge'>{user.kno}</td>
                      <td className='thsmallcustlarge'>{user.cname}</td>
                      <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                      <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                      <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                      <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                      <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                      <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                      <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt).toFixed(2) : 0}</td>
                    </tr>
                  )
                    :
                    DataSource && DataSource.map((user, index) =>
                      <tr key={index}>
                        <td className='thsmallcust'>{formatDate(user.date)}</td>
                        <td className='thsmallcustlarge'>{user.kno}</td>
                        <td className='thsmallcustlarge'>{user.cname}</td>
                        <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                        <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                        <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                        <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                        <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                        <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                        <td className='thsmallcust'>{user.amt && user.amt !== "" ? parseFloat(user.amt).toFixed(2) : 0}</td>
                      </tr>
                    )
                  }

                </tbody>
                <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                  <td className='thsmallcust'>एकूण</td>
                  <td className='thsmallcustlarge'>-</td>
                  <td className='thsmallcustlarge'>-</td>
                  <td className='thsmallcust'>-</td>
                  <td className='thsmallcust'>-</td>
                  <td className='thsmallcust'>
                      {value.length > 0
                          ? tableFilter.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)
                          : DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)}
                  </td>
                  <td className='thsmallcust'>-</td>
                  <td className='thsmallcust'>-</td>
                  <td className='thsmallcust'>-</td>
                  <td className='thsmallcust'>
                      {value.length > 0
                          ? tableFilter.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)
                          : DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}
                  </td>

                </tr>
              </table>
              : rtype === "bydate" ?
                <table id="myTablebill" className="table table-bordered">
                  <thead>
                    <tr>
                      <th className='thsmall' scope="col">दिनांक</th>
                      <th className='thsmall' scope="col">वेळ</th>
                      <th className='thsmall' scope="col">म्हैस कलेक्शन (+)</th>
                      <th className='thsmall' scope="col">म्हैस संघ विक्री(-)</th>
                      <th className='thsmall' scope="col">म्हैस रतीब (-)</th>
                      <th className='thsmall' scope="col">म्हैस रक्कम (₹)</th>
                      <th className='thsmall' scope="col">म्हैस वाढ तूट (=)</th>
                      <th className='thsmall' scope="col">गाय कलेक्शन</th>
                      <th className='thsmall' scope="col">गाय संघ विक्री</th>
                      <th className='thsmall' scope="col">गाय रतीब(-)</th>
                      <th className='thsmall' scope="col">गाय रक्कम (₹)</th>
                      <th className='thsmall' scope="col">गाय वाढ तूट(=)</th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    {value.length > 0 ? tableFilter.map((user, index) =>
                       <tr key={index}>
                       <td className='thsmallcust'>{formatDate(user.date)}</td>
                       <td className='thsmallcust'>{user.time}</td>
                       <td className='thsmallcust'>{user.mhliter && user.mhliter !== "" ? parseFloat(user.mhliter).toFixed(2) : "-"}</td>
                       <td className='thsmallcust'>{user.sanghtotalmh && user.sanghtotalmh !== "" ? parseFloat(user.sanghtotalmh).toFixed(2) : "-"}</td>
                       <td className='thsmallcust'>{user.totalratibmh && user.totalratibmh !== "" ? parseFloat(user.totalratibmh).toFixed(2) : 0}</td>
                       <td className='thsmallcust'>{user.mhamt && user.mhamt !== "" ? parseFloat(user.mhamt).toFixed(2) : 0}</td>
                       <td className={parseFloat(user.vadhtutmh) < 0 ? 'thsmallredd' : 'thsmallgreenn'}>{user.vadhtutmh && user.vadhtutmh !== "" ? parseFloat(user.vadhtutmh).toFixed(2) : 0}</td>
                      
                       <td className='thsmallcust'>{user.gayliter && user.gayliter ? parseFloat(parseFloat(user.gayliter)).toFixed(2) : 0}</td>
                       <td className='thsmallcust'>{user.sanghtotalgay ? parseFloat(parseFloat(user.sanghtotalgay)).toFixed(2) : 0}</td>
                       <td className='thsmallcust'>{user.totalratibgay ? parseFloat(parseFloat(user.totalratibgay)).toFixed(2) : 0}</td>
                       <td className='thsmallcust'>{user.gayamt && user.gayamt !== "" ? parseFloat(user.gayamt).toFixed(2) : 0}</td>
                       <td className={parseFloat(user.vadhtutgay) < 0 ? 'thsmallredd' : 'thsmallgreenn'}>{user.vadhtutgay && user.vadhtutgay !== "" ? parseFloat(user.vadhtutgay).toFixed(2) : 0}</td>
                      

                     </tr>
                    )
                      :
                      DataSource2 && DataSource2.map((user, index) =>
                        <tr key={index}>
                          <td className='thsmallcust'>{formatDate(user.date)}</td>
                          <td className='thsmallcust'>{user.time}</td>
                          <td className='thsmallcust'>{user.mhliter && user.mhliter !== "" ? parseFloat(user.mhliter).toFixed(2) : "-"}</td>
                          <td className='thsmallcust'>{user.sanghtotalmh && user.sanghtotalmh !== "" ? parseFloat(user.sanghtotalmh).toFixed(2) : "-"}</td>
                          <td className='thsmallcust'>{user.totalratibmh && user.totalratibmh !== "" ? parseFloat(user.totalratibmh).toFixed(2) : 0}</td>
                          <td className='thsmallcust'>{user.mhamt && user.mhamt !== "" ? parseFloat(user.mhamt).toFixed(2) : 0}</td>
                          <td className={parseFloat(user.vadhtutmh) < 0 ? 'thsmallredd' : 'thsmallgreenn'}>{user.vadhtutmh && user.vadhtutmh !== "" ? parseFloat(user.vadhtutmh).toFixed(2) : 0}</td>
                         
                          <td className='thsmallcust'>{user.gayliter && user.gayliter ? parseFloat(parseFloat(user.gayliter)).toFixed(2) : 0}</td>
                          <td className='thsmallcust'>{user.sanghtotalgay ? parseFloat(parseFloat(user.sanghtotalgay)).toFixed(2) : 0}</td>
                          <td className='thsmallcust'>{user.totalratibgay ? parseFloat(parseFloat(user.totalratibgay)).toFixed(2) : 0}</td>
                          <td className='thsmallcust'>{user.gayamt && user.gayamt !== "" ? parseFloat(user.gayamt).toFixed(2) : 0}</td>
                          <td className={parseFloat(user.vadhtutgay) < 0 ? 'thsmallredd' : 'thsmallgreenn'}>{user.vadhtutgay && user.vadhtutgay !== "" ? parseFloat(user.vadhtutgay).toFixed(2) : 0}</td>
                         

                        </tr>
                      )
                    }

                  </tbody>
                  <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                    <td className='thsmallcust'>एकूण</td>
                    <td className='thsmallcust'>-</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.mhliter), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.sanghtotalmh), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.totalratibmh), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.mhamt), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.vadhtutmh), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.gayliter), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.sanghtotalgay), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.totalratibgay), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.gayamt), 0).toFixed(2)}</td>
                    <td className='thsmallcust'>{DataSource2.reduce((total, user) => total + parseFloat(user.vadhtutgay), 0).toFixed(2)}</td>
                  </tr>
                </table>
                :
                rtype === "mobileuser" ?
                  <table id="myTablebill" className="table table-bordered">
                    <thead>
                      <tr>
                      <th className='thsmall' scope="col">अ.क्र</th>
                        <th className='thsmall' scope="col">दिनांक</th>
                        <th className='thsmall' scope="col">खाते क्र</th>
                        <th className='thsmall' scope="col">नाव</th>
                        <th className='thsmall' scope="col">वेळ</th>
                        <th className='thsmall' scope="col">प्रकार</th>
                        <th className='thsmall' scope="col">लिटर</th>
                        <th className='thsmall' scope="col">फॅट</th>
                        <th className='thsmall' scope="col">एसएनएफ</th>
                        <th className='thsmall' scope="col">दर</th>
                        <th className='thsmall' scope="col">रक्कम</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value.length > 0 ? tableFilter.map((user, index) =>
                        <tr key={index}>
                         <td className='thsmallcustlarge'>{index + 1}</td>
                          <td className='thsmallcust'>{formatDate(user.date)}</td>
                          <td className='thsmallcustlarge'>{user.kno}</td>
                          <td className='thsmallcustlarge'>{user.name}</td>
                          <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                          <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                          <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                          <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                          <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                          <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                          <td className='thsmallcust'>{user.amt && user.amt !== "" ? user.amt : 0}</td>
                        </tr>
                      )
                        :
                        DataSource && DataSource.map((user, index) =>
                          <tr key={index}>
                             <td className='thsmallcustlarge'>{index + 1}</td>
                            <td className='thsmallcust'>{formatDate(user.date)}</td>
                            <td className='thsmallcustlarge'>{user.kno}</td>
                            <td className='thsmallcustlarge'>{user.name}</td>
                            <td className='thsmallcust'>{user.time && user.time !== "" ? user.time : "-"}</td>
                            <td className='thsmallcust'>{user.type && user.type !== "" ? user.type : "-"}</td>
                            <td className='thsmallcust'>{user.liter && user.liter !== "" ? parseFloat(user.liter) : 0}</td>
                            <td className='thsmallcust'>{user.fat && user.fat !== "" ? parseFloat(user.fat) : 0}</td>
                            <td className='thsmallcust'>{user.snf && user.snf !== "" ? parseFloat(user.snf) : 0}</td>
                            <td className='thsmallcust'>{user.rate && user.rate !== "" ? parseFloat(user.rate) : 0}</td>
                            <td className='thsmallcust'>{user.amt && user.amt !== "" ? user.amt : 0}</td>
                          </tr>
                        )
                      }

                    </tbody>
                    <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                      <td className='thsmallcust'>एकूण</td>
                      <td className='thsmallcustlarge'>-</td>
                      <td className='thsmallcustlarge'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.liter), 0).toFixed(2)}</td>
                      <td className='thsmallcust'>
                        {(
                          DataSource.reduce((total, user) => total + parseFloat(user.liter * user.fat), 0) /
                          DataSource.reduce((total, user) => total + parseFloat(user.liter), 0)
                        ).toFixed(1)}
                      </td>

                      <td className='thsmallcust'>
                        {(
                          DataSource.reduce((total, user) => total + parseFloat(user.liter * user.snf), 0) /
                          DataSource.reduce((total, user) => total + parseFloat(user.liter), 0)
                        ).toFixed(1)}
                      </td>

                      <td className='thsmallcust'>-</td>
                      <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.amt), 0).toFixed(2)}</td>

                    </tr>
                  </table>
                  :
                  rtype === "ekmobileuser" ?
                    <table id="myTablebill" className="table table-bordered">
                      <thead>
                        <tr>
                          <th className='thsmall' scope="col">दिनांक</th>
                          <th className='thsmall' scope="col">प्रकार</th>
                          <th className='thsmall' scope="col">सकाळ लिटर</th>
                          <th className='thsmall' scope="col">सकाळ रक्कम</th>
                          <th className='thsmall' scope="col">सरा.फॅट</th>
                          <th className='thsmall' scope="col">सरा.SNF</th>
                          <th className='thsmall' scope="col">संध्या लिटर</th>
                          <th className='thsmall' scope="col">संध्या रक्कम</th>
                          <th className='thsmall' scope="col">सरा.फॅट</th>
                          <th className='thsmall' scope="col">सरा.SNF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {value.length > 0 ? tableFilter.map((user, index) =>
                          <tr key={index}>
                            <td className='thsmallcust'>{formatDate(user.date)}</td>
                            <td className='thsmallcustlarge'>{user.type}</td>
                            <td className='thsmallcustlarge'>{user.mormhliter}</td>
                            <td className='thsmallcust'>{user.mormhamt && user.mormhamt !== "" ? user.mormhamt : "-"}</td>
                            <td className='thsmallcust'>{user.mormhfat && user.mormhfat !== "" ? user.mormhfat : "-"}</td>
                            <td className='thsmallcust'>{user.mormhsnf && user.mormhsnf !== "" ? parseFloat(user.mormhsnf) : 0}</td>
                            <td className='thsmallcust'>{user.evnmhliter && user.evnmhliter !== "" ? parseFloat(user.evnmhliter) : 0}</td>
                            <td className='thsmallcust'>{user.evnmhamt && user.evnmhamt !== "" ? parseFloat(user.evnmhamt) : 0}</td>
                            <td className='thsmallcust'>{user.evnmhfat && user.evnmhfat !== "" ? parseFloat(user.evnmhfat) : 0}</td>
                            <td className='thsmallcust'>{user.evnmhsnf && user.evnmhsnf !== "" ? parseFloat(user.evnmhsnf) : 0}</td>
                          </tr>
                        )
                          :
                          DataSource && DataSource.map((user, index) =>
                            <tr key={index}>
                              <td className='thsmallcust'>{formatDate(user.date)}</td>
                              <td className='thsmallcustlarge'>{user.type}</td>
                              <td className='thsmallcustlarge'>{user.mormhliter}</td>
                              <td className='thsmallcust'>{user.mormhamt && user.mormhamt !== "" ? user.mormhamt : "-"}</td>
                              <td className='thsmallcust'>{user.mormhfat && user.mormhfat !== "" ? user.mormhfat : "-"}</td>
                              <td className='thsmallcust'>{user.mormhsnf && user.mormhsnf !== "" ? parseFloat(user.mormhsnf) : 0}</td>
                              <td className='thsmallcust'>{user.evnmhliter && user.evnmhliter !== "" ? parseFloat(user.evnmhliter) : 0}</td>
                              <td className='thsmallcust'>{user.evnmhamt && user.evnmhamt !== "" ? parseFloat(user.evnmhamt) : 0}</td>
                              <td className='thsmallcust'>{user.evnmhfat && user.evnmhfat !== "" ? parseFloat(user.evnmhfat) : 0}</td>
                              <td className='thsmallcust'>{user.evnmhsnf && user.evnmhsnf !== "" ? parseFloat(user.evnmhsnf) : 0}</td>
                            </tr>
                          )
                        }

                      </tbody>
                      <tr style={{ backgroundColor: 'lightgray', fontWeight: 'bold' }}>
                        <td className='thsmallcust'>एकूण</td>
                        <td className='thsmallcustlarge'>-</td>
                        <td className='thsmallcustlarge'>{DataSource.reduce((total, user) => total + parseFloat(user.mormhliter), 0).toFixed(2)}</td>
                        <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.mormhamt), 0).toFixed(2)}</td>
                        <td className='thsmallcust'>-</td>
                        <td className='thsmallcust'>-</td>
                        <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.evnmhliter), 0).toFixed(2)}</td>
                        <td className='thsmallcust'>{DataSource.reduce((total, user) => total + parseFloat(user.evnmhamt), 0).toFixed(2)}</td>
                        <td className='thsmallcust'>-</td>
                        <td className='thsmallcust'>-</td>

                      </tr>
                    </table>
                    :
                    <table id="myTablebill" className="table table-bordered">
                      <thead>
                        <tr>
                          <th className='thsmall' scope="col">Sr</th>
                          <th className='thsmall' scope="col">Ac No</th>
                          <th className='thsmall' scope="col">Private union</th>
                          <th className='thsmall' scope="col">From</th>
                          <th className='thsmall' scope="col">To</th>
                          <th className='thsmall' scope="col">District</th>
                          <th className='thsmall' scope="col">Taluka</th>
                          <th className='thsmall' scope="col">Village</th>
                          <th className='thsmall' scope="col">Supplier</th>
                          <th className='thsmall' scope="col">Farmer ID</th>
                          <th className='thsmall' scope="col">Tag Number</th>
                          <th className='thsmall' scope="col">Name</th>
                          <th className='thsmall' scope="col">Qty</th>
                          <th className='thsmall' scope="col">Avg.FAT</th>
                          <th className='thsmall' scope="col">Avg. SNF</th>
                          <th className='thsmall' scope="col">Avg. Rate</th>
                          <th className='thsmall' scope="col">Amount</th>
                          <th className='thsmall' scope="col">Bank</th>
                          <th className='thsmall' scope="col">Branch</th>
                          <th className='thsmall' scope="col">A/c Number</th>
                          <th className='thsmall' scope="col">IFSC</th>
                          <th className='thsmall' scope="col">Aadhar</th>
                          <th className='thsmall' scope="col">Mobile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          DataSource3 && DataSource3.map((user, index) =>
                            <tr key={index}>
                              <td className='thsmallcust'>{index + 1}</td>
                              <td className='thsmallcust'>{user.kno}</td>
                              <td className='thsmallcust'>{"-"}</td>
                              <td className='thsmallcust'>{formatDate(input.date1)}</td>
                              <td className='thsmallcust'>{formatDate(input.date2)}</td>
                              <td className='thsmallcust'>{localStorage.getItem("dist")}</td>
                              <td className='thsmallcust'>{localStorage.getItem("tal")}</td>
                              <td className='thsmallcust'>{user.address}</td>
                              <td className='thsmallcust'>{localStorage.getItem("kendra")}</td>
                              <td className='thsmallcust'>{user.farmerid}</td>
                              <td className='thsmallcust'>{user.tag}</td>
                              <td className='thsmallcust'>{user.cname}</td>
                              <td className='thsmallcust'>{parseFloat(user.tliter).toFixed(2)}</td>
                              <td className='thsmallcust'>{parseFloat(user.avg_fat).toFixed(2)}</td>
                              <td className='thsmallcust'>{parseFloat(user.avg_snf).toFixed(2)}</td>
                              <td className='thsmallcust'>{parseFloat(user.avg_rate).toFixed(2)}</td>
                              <td className='thsmallcust'>{parseFloat(user.total_amt).toFixed(2)}</td>
                              <td className='thsmallcust'>{user.bankname}</td>
                              <td className='thsmallcust'>{user.branch}</td>
                              <td className='thsmallcust'>{user.accno}</td>
                              <td className='thsmallcust'>{user.ifsc}</td>
                              <td className='thsmallcust'>{user.aadhar}</td>
                              <td className='thsmallcust'>{user.mobno}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
          }
        </div>
      </div>

    </div>

  )
}

export default Sanklanreport
