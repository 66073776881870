import React, { useContext, useEffect, useRef, useState } from 'react'
import './Trancontents.css'
import milkcan from "../../imgs/milkcan.png";
import khadya from "../../imgs/khadya.png";
import milkvan from "../../imgs/milkvan.png";
import farmer from "../../imgs/milkfamer.png";
import ratib from "../../imgs/ratib.webp";
import cash from "../../imgs/cash.png";
import ratetable from "../../imgs/ratetable.png";
import settings from "../../imgs/settings.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import Newcustomer from '../Newcustomer/Newcustomer';
import Newkendra from '../Newkendra/Newkendra';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Ratetable from '../Ratetable/Ratetable';
import Setting from '../Settings/Settings';
import Nrepair from '../Nrepair/Nrepair';
import Sanklan from '../Sanklan/Sanklan'
import Sanklanbykno from '../Sanklanbykno/Sanklanbykno'
import Globalcontext from '../../Context/Globalcontext';
import Khpur from '../Khpur/Khpur';
import Khsale from '../Khsale/Khsale';
import qr from "../../imgs/QR.png";
import Uchal from '../Uchal/Uchal';
import Sanghvikri from '../Sanghvikri/Sanghvikri';
import Ratibvikri from '../Ratibvikri/Ratibvikri';
import Ratibjma from '../Ratibjma/Ratibjma';
import Khpurjma from '../Khpurjma/Khpurjma';


const Trancontents = (props) => {

    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource, setHeadname } = useContext(Globalcontext);
    const refprint = useRef(null);
    const refprintclose = useRef(null);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [expirebox, setExpirebox] = useState(false);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const handlePrint = () => {
        refprint.current.click();
    }

    function formatDate(inputDate) {
        // Convert the input date string to a Date object
        const date = new Date(inputDate);

        // Define month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug',
            'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date as 'day Month Year'
        const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

        return formattedDate;
    }



    const handleprintclose = () => {
        refprintclose.current.click();
    }

    const areUSure = (choose) => {

        handleDialog("", false);

    };

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const showdialog = () => {
        setkopen(1);
        handleDialog("केंद्र उपलब्ध नाही?", true, `नवीन केंद्र तयार करायचे?`);
    }
    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handledeletekendra = () => {

    }

    useEffect(() => {

        if (localStorage.getItem("kendra") === "Not available" && kopen === 0) {
            showdialog();
        }
        else {
            if (sanksource === "custback") {

                setsubmenu("sanklan")
                setShowBackImage(true);
                setMastermenu(false);
                setTransitionEnded(true);
                // if (submenu === "customer") {
                //     setkopen(0);
                // }
            }
        }

        if (localStorage.getItem("contact") === "" || localStorage.getItem("contact") === null) {
          
            navigate("/");
            localStorage.setItem("status", "");
            localStorage.setItem("contact", "");
        }
    


        // if (localStorage.getItem('contact') === "9975337297") {

        //     const currentDate = new Date();

        //     // Target date
        //     const targetDate = new Date(localStorage.getItem("vdate"));

        //     // Calculate the difference in milliseconds
        //     const differenceInTime = targetDate.getTime() - currentDate.getTime();

        //     // Convert the difference from milliseconds to days
        //     const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

        //     // Set the result in state
        //     setDaysRemaining(differenceInDays);

        //     if (differenceInDays <= 7) {
        //         handlePrint();
        //         setExpirebox(true);
        //     }
        // }
    }, [])

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("दैनंदिन व्यवहार");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    const logout = (contact) => {
        localStorage.setItem("status", "");
        localStorage.setItem("contact", "");
        localStorage.setItem("contactplant", "");
        localStorage.setItem("kendra", "Not available");
        localStorage.setItem("kmobno", "");
        localStorage.setItem("ksr", "");
        localStorage.setItem("status", "");
        localStorage.setItem("vdate", "");
    
      
        props.setProgress(50);
        setTimeout(() => {
          props.setProgress(100);
        }, 200);
    
        navigate("/");
     
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refprint}
                data-bs-target="#printinput"
            >
            </button>

            <div className="modal fade" id="printinput" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refprintclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="exampleModalLabel">आपल्या सॉफ्टवेअरची व्हॅलिडिटी काही दिवसात समाप्त होत आहे.</h5>
                            <span className='note'>सूचना : कृपया आपली वार्षिक रक्कम भरून घ्यावी, अन्यथा आपले सॉफ्टवेअर बंद होऊ शकते!</span>
                        </div>

                        <div className="expiry">

                            <span className='noteblue'>१. समाप्त दिनांक: {formatDate(localStorage.getItem("vdate")) + ", शिल्लक दिवस: " + daysRemaining}</span>
                            <span className='noteblue'>२. वार्षिक रक्कम ही आपल्या सॉफ्टवेअर पॅकेज नुसार असेल याची नोंद घ्यावी.(रू.१००० ते रु.५०००)</span>
                            <span className='noteblue'>३. खाली दिलेला QR स्कॅन करुन आपण आपले पेमेंट करू शकता.</span>
                            <span className='noteblue'>4. संपर्क क्रमांक: (91) 800-788-9200</span>

                            <span className='noteblack'>Scan QR Code Below</span>

                            <img className='qrimg' src={qr} alt="" />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleprintclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={handleprintclose}>ओके</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='line' />
            <div className="maincon">

                <span className={`heading1 ${mastermenu ? '' : 'hidden'}`}>दैनंदिन व्यवहार</span>
                <div className={`Dashcontents ${mastermenu ? '' : 'hidden'}`} onTransitionEnd={handleTransitionEnd}>
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t4dark), var(--t4dark))"} head={"संकलन"} img={<img src={milkcan} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3dark), var(--t3dark))"} head={"सभासदानुसार संकलन"} img={<img src={farmer} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t5dark), var(--t5dark))"} head={"संघ विक्री"} img={<img src={milkvan} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t2dark), var(--t2dark))"} head={"रतीब विक्री"} img={<img src={ratib} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t6dark), var(--t6dark))"} head={"खाद्य खरेदी"} img={<img src={khadya} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t3dark), var(--t3dark))"} head={"खाद्य विक्री"} img={<img src={khadya} alt="" />} />
                    <Box setsubmenu={setsubmenu} visibility={visibility} background={"linear-gradient(179deg, var(--t1dark), var(--t1dark))"} head={"एडव्हांस/उचल"} img={<img src={cash} alt="" />} />


                </div>

                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">

                    </div>
                    <div className="second">
                        <div className='line' />
                        <span className='firstspan'>1. येथे आपण आपले दैनंदिन व्यवहाराच्या एंट्रीज करू शकता.</span>
                        <span className='infospan'>2. उदा. संकलन,खाद्य विक्री/खरेदी किंवा उचल देणे.</span>
                        <span className='infospan'>3. सॉफ्टवेअर मधील हा महत्त्वाचा भाग आहे.</span>
                    </div>

                </div>


                <div className={`cust ${mastermenu ? 'hidden' : ''}`}>
                    {transitionEnded && showBackImage && (
                        submenu === "customer" ? <Newcustomer setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                            submenu === "kendra" ? <Newkendra setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                submenu === "ratetable" ? <Ratetable setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                    submenu === "settings" ? <Setting setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                        submenu === "nrepair" ? <Nrepair setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                            submenu === "sanklan" ? <Sanklan setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                submenu === "sanklanbykno" ? <Sanklanbykno setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                    submenu === "sanghvikri" ? <Sanghvikri setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                        submenu === "ratibvikri" ? <Ratibvikri setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                            submenu === "ratibjma" ? <Ratibjma setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                submenu === "kpur" ? <Khpur setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                    submenu === "khpurjma" ? <Khpurjma setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                        submenu === "ksale" ? <Khsale setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                            submenu === "uchal" ? <Uchal setsubmenu={setsubmenu} setKendraValue={props.setKendraValue} setkopen={setkopen} setProgress={props.setProgress} visibility={visibility} handleTransitionEnd={handleTransitionEnd} setTransitionEnded={setTransitionEnded} /> :
                                                                                ""
                    )}
                </div>

            </div>
            <div className="secrow">
                <div className="leftsecrow">

                </div>
                {
                    submenu !== "sanklan" && submenu !== "sanklanbykno" && submenu !== "sanghvikri" && submenu !== "ratibvikri" && submenu != "kpur" && submenu != "ksale" && submenu != "uchal" && submenu != "ratibjma" && submenu != "khpurjma"  ?
                        <div className="imgcon">
                            <img className='imgcowtran' src={Cowleft} alt="" />
                        </div>
                        : ""
                }

            </div>

            {dialog.isLoading && (
                <Dialog
                    //Update
                    submessage={dialog.nameProduct}
                    onDialog={areUSure}
                    message={dialog.message}
                    functionname={openkendra}
                    button1text="नाही"
                    button2text="होय"
                />
            )}
        </>
    )
}

export default Trancontents
