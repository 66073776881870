import React, { useContext, useEffect, useState } from 'react'
import './Dashboard.css'
import { motion } from 'framer-motion';
import Tangelo from "../../imgs/milkcan.png";
import Next from "../../imgs/next.png";
import Dashcontendts from '../Dashcontents/Dashcontendts';
import Sidebar from '../Sidebar/Sidebar';
import Globalcontext from '../../Context/Globalcontext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useRef } from 'react';
import { SingleBed } from '@mui/icons-material';
import Treport from '../Treport/Treport';
let randomNumber = "0";

const Dashboard = (props) => {

  const reflogout = useRef(null);
  const refdeleteclose = useRef(null);

  const navigate = useNavigate();
  const notify = (msg) => toast(msg);
  const [kendraValue, setKendraValue] = useState(localStorage.getItem('kendra') + "," + localStorage.getItem('city'));
  const { submenu, setsubmenu, newcustsource, setNewcustsource, kendrasource, setKendrasource, headname, setHeadname } = useContext(Globalcontext);

  const handledeleteclose = () => {
    refdeleteclose.current.click();
  }

  const opennewkendra = () => {
    refdeleteclose.current.click();
  }

  const handlelogout = () => {
    reflogout.current.click();
  }

  useEffect(()=>{
    randomNumber = Math.random();
    console.log(props.isLoggedIn);
  })

  const openkendra = () => {
    navigate("/Dashboard");
    setsubmenu("kendra");
    setNewcustsource("kendra");
    setKendrasource("Dashboard");
  }

  const logout = (contact) => {

    localStorage.setItem("status", "");
    localStorage.setItem("contact", "");
    localStorage.setItem("contactplant", "");
    localStorage.setItem("kendra", "Not available");
    localStorage.setItem("kmobno", "");
    localStorage.setItem("ksr", "");
    localStorage.setItem("status", "");
    localStorage.setItem("vdate", "");

    refdeleteclose.current.click();
    props.setProgress(50);
    setTimeout(() => {
      props.setProgress(100);
    }, 200);

    navigate("/");
    notify("Logged Out.");
  }

  const bodycontainerVariants = {
    true: {
      marginLeft: '0px',
      width: '-webkit-calc(100vw - 14rem)'
    },
    false: {
      marginLeft: '-130px',
      width: '-webkit-calc(100vw - 6rem)',
      transition: {
        delay: 0.6
      }
    }
  }

  return (

    <div>

      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        hidden="{true}"
        ref={reflogout}
        data-bs-target="#exampleModaldelete"
      >
      </button>

      <div className="modal fade" id="exampleModaldelete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refdeleteclose} aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <h4 className="modal-title" id="exampleModalLabel">सोफ्टवेअर बंद करायचे?</h4>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handledeleteclose}  >नाही</button>
              <button type="button" className="btn btn-danger" onClick={logout}>होय</button>
            </div>
          </div>
        </div>
      </div>

      <motion.div
        initial={`${props.open}`}
        animate={`${props.open}`}
        variants={bodycontainerVariants}
        className="body_container">

        <motion.div
          className="body">
          <div className="profileinfo">
            <div className="firstpart2">
              <img className='imglogo' src={Tangelo} alt="" />
              <span>{kendraValue}</span>
              <img onClick={openkendra} className='next' src={Next} alt="" />
            </div>
            <div className="secondpart">
            <Treport rand ={randomNumber}/>
              <span className='headnamee'>{headname}/</span>
              <button onClick={handlelogout} className='signout'>लोग आऊट</button>
            </div>
          </div>


          <Dashcontendts setKendraValue={setKendraValue} logout={logout} setProgress={props.setProgress} />
        </motion.div>

      </motion.div>
      <ToastContainer />
    </div>
  )
}

export default Dashboard
