import React, { useContext, useRef, useState } from 'react'
import './Helpcontents.css'
import call from "../../imgs/call.webp";
import mail from "../../imgs/mail.png";
import khate from "../../imgs/khate.webp";
import sanklan from "../../imgs/sanklanreport.png";
import khadya from "../../imgs/khadya.png";
import pregister from "../../imgs/pregister.png";
import Box from '../Box/Box';
import Cowleft from "../../imgs/cowleft.gif";
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog/Dialog';
import Globalcontext from '../../Context/Globalcontext';
import Bill from '../Bill/Bill';
import Pregister from '../Pregister/Pregister';
import Customerreport from '../Customerreport/Customerreport';
import Sanklanreport from '../Sanklanreport/Sanklanreport';
import Khadyaadvreport from '../Khadyaadvreport/Khadyaadvreport';

const Helpcontents = (props) => {
    const [mastermenu, setMastermenu] = useState(true);
    const [showBackImage, setShowBackImage] = useState(false);
    const [transitionEnded, setTransitionEnded] = useState(true);
    const [kopen, setkopen] = useState(0);
    const { submenu, setsubmenu } = useContext(Globalcontext);
    const refnewkendra = useRef(null);
    const refnewkendraclose = useRef(null);
    const { sanksource, setSanksource,setHeadname} = useContext(Globalcontext);

    const navigate = useNavigate();
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        nameProduct: ""
    });

    const areUSure = (choose) => {

        handleDialog("", false);

    };

    const openkendra = () => {

        setShowBackImage(true);
        setMastermenu(false);
        setTransitionEnded(true);
        setsubmenu("kendra");

        setkopen(1);
    }

    const handleDialog = (message, isLoading, nameProduct) => {
        setDialog({
            message,
            isLoading,
            nameProduct
        });
    };

    const visibility = () => {
        localStorage.setItem("glcustsr", "");
        setTransitionEnded(false);
        setMastermenu(!mastermenu);
        setkopen(0);
        setsubmenu("");
        setHeadname("रिपोर्ट्स");
    }

    const handleTransitionEnd = () => {
        if (!mastermenu) {
            setShowBackImage(true);
        } else {
            setShowBackImage(false);
        }
        setTransitionEnded(true);
    };

    return (
        <>
            <div className='line' />
            <div className="maincon">

                <div className="rowreport">
                    <div className="helpfirst">
                
                        <div className='helpmain'>
                            <img className='smallimg' src={call} alt="" />
                            <span className="headinghelp">सॉफ्टवेअर मदत - (+91) 800-788-9200</span>
                        </div>
                        {/* <div className='helpmain'>
                            <img className='smallimg' src={call} alt="" />
                            <span className="headinghelp">कंप्यूटर,प्रिंटर व इतर मदत - (+91) 9765 265 522, (+91) 7972 904 592</span>
                        </div> */}
                        <div className='helpmain'>
                            <img className='smallimg' src={mail} alt="" />
                            <span className="headinghelp">support@idairy.co.in</span>
                        </div>
                      
                    </div>

                 
                </div>

                <div className={`rowbox ${mastermenu ? '' : 'hidden'}`}>
                    <div className="first">
                    </div>
                    <div className="second">
                        <div className='line' />
                        <span className='firstspan'>1. कॉल वेळ - सकाळी १० ते रात्री ९ पर्यंत.</span>
                        <span className='infospan'>2. कॉल व्यस्त असल्यास आपणास त्वरित कॉल-बॅक करण्यात येईल.</span>
                       
                    </div>
                </div>
            </div>
        </>

    )
}

export default Helpcontents
