import React, { useEffect, useState } from 'react'
import './Settings.css'
import back from "../../imgs/backexam.png";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { UilEditAlt, UilCloudUpload } from '@iconscout/react-unicons'

const Settings = (props) => {
    const [newedit, setNewedit] = useState("new");
    const [input, setInput] = useState({ mhvah: "", gayvah: "", mhthev: "", gaythev: "", kapatbill: "", hptabill: "", ikapatliter: "", ikapatbill: "" });
    const [newrow, setNewrow] = useState({ rowno: "", mhvah: "", gayvah: "", mhthev: "", gaythev: "", kapatbill: "", hptabill: "", ikapatliter: "", ikapatbill: "" });
    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);
    const [DataSource, setDataSource] = useState([]);
    const [tableFilter, setTableFilter] = useState([]);
    const [value, setValue] = useState('');
    const [rowindexmain, setrowindexmain] = useState(0);
    const [colindexmain, setcolindexmain] = useState(0);
    const [mainvalue, setmainvalue] = useState(0);

    const handleChange = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }))
    }
    const handleedit = () => {


    }

    useEffect(() => {
        getcustomers();
        getsettings();
    }, [])

    async function opensr(sr) {
        localStorage.setItem("glcustsr",sr);
        props.setsubmenu("customer");
    }

    async function getcustomers() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getaccoounttemp.php', { mainuser: localStorage.getItem("contact"),mainuser2:mainuser2 }).then(function sample(response) {

            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    async function getsettings() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getsettings.php', { mainuser: localStorage.getItem("contact"),mainuser2:mainuser2 }).then(function sample(response) {
            
            if (response.data) {    
                response.data.map((user, index) =>
                (
                    setInput({ mhvah: user.mhvah, gayvah: user.gayvah, mhthev: user.mhthev, gaythev: user.gaythev, kapatbill: user.kapatbill, hptabill: user.hptabill, ikapatliter: user.ikapatliter, ikapatbill: user.ikapatbill })

                ))
                 setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const savesettings = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        if (input.mhvah === "" || input.mhthev === "" || input.gaythev === "" || input.kapatbill === "" || input.hptabill === "" || input.ikapatliter === "" || input.ikapatbill === "") {
            notifyerror("सर्व सेटिंग सेट करा.")
        }
        else {

            let rawData = {
                mainuser: localStorage.getItem("contact"),
                mhvah: input.mhvah,
                gayvah: input.gayvah,
                mhthev: input.mhthev,
                gaythev: input.gaythev,
                kapatbill: input.kapatbill,
                hptabill: input.hptabill,
                ikapatliter: input.ikapatliter,
                ikapatbill: input.ikapatbill,
                mainuser2:mainuser2,
            }
            rawData = JSON.stringify(rawData)

            let formData = new FormData()
            formData.append('data', rawData)

            axios.post('https://idairy.co.in/dairy/php/cust/savesettings.php', formData, {
                'Content-Type': 'multipart/form-data',

            }).then(function sample(response) {
              //  console.log(response.data);
                if (response.data.trim() === "Successfully updated.") {
                    notify("नवीन सेटिंग्स लागू झाले!");
                    getcustomers();
                }

            }).catch(err => {
                console.log(err);
            });
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <div className="settingmain">
            <div className="firstrow">
                <img onClick={props.visibility} className='back' src={back} alt="" />
            </div>

            <div className="firstrow">
                <span>सर्व सभासदांसाठी सेट करणे</span>
            </div>
            <div className="firstrow">
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>म्हैस ठेव/लिटर</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="mhthev"
                            name="mhthev"
                            value={input.mhthev}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>एड्व्हांस हप्ता/बिल</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="kapatbill"
                            name="kapatbill"
                            value={input.kapatbill}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>ई.कपात/लिटर</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="ikapatliter"
                            name="ikapatliter"
                            value={input.ikapatliter}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>म्हैस वाहतूक/लिटर</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="mhvah"
                            name="mhvah"
                            value={input.mhvah}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>

            </div>
            <div className="firstrow">
              
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>गाय ठेव/लिटर</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="gaythev"
                            name="gaythev"
                            value={input.gaythev}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>--</span>
                    {/* खाद्य हप्ता/बिल */}
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="hptabill"
                            name="hptabill"
                            value={input.hptabill}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>ई.कपात/बिल</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="ikapatbill"
                            name="ikapatbill"
                            value={input.ikapatbill}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>
                <div className="mb-2 mx-2 bb">
                    <span className='subhead'>गाय वाहतूक/लिटर</span>
                    <div className="selection">
                        <input className="form-control centered-input editmode"
                            id="gayvah"
                            name="gayvah"
                            value={input.gayvah}
                            onChange={handleChange}
                            type="number"
                            step="0.1"
                            autoComplete='off' />

                    </div>
                </div>


            </div>
            <div className="mb-2 mx-2 bb">
                <button onClick={savesettings} className='button'>सर्व सभासदांना सेट करणे</button>
            </div>
            <div class="table-containersettings">
                <table id="myTable" className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">खाते नंबर</th>
                            <th scope="col">नाव</th>
                            <th scope="col">म्हैस ठेव</th>
                            <th scope="col">गाय ठेव</th>
                            <th scope="col">एड्व्हांस हप्ता</th>
                            <th scope="col">खाद्य हप्ता</th>
                            <th scope="col">इ.कपात/लिटर </th>
                            <th scope="col">इ.कपात/बिल</th>
                            <th scope="col">म्हैस वाहतूक/ली</th>
                            <th scope="col">गाय वाहतूक/ली</th>
                            <th scope="col">एक्शन</th>
                        </tr>
                    </thead>
                    <tbody>
                        {value.length > 0 ? tableFilter.map((user, index) =>
                            <tr key={index}>

                                <td>{user.kno}</td>
                                <td>{user.cname}</td>
                                <td>{user.mhthev}</td>
                                <td>{user.gaythev}</td>
                                <td>{user.kapatbill}</td>
                                <td>{user.hptabill}</td>
                                <td>{user.ikapatliter}</td>
                                <td>{user.ikapatbill}</td>
                                <td>{user.mhvah}</td>
                                <td>{user.gayvah}</td>
                                <td><UilEditAlt onClick={() => {opensr(user.kno) }} className="icsettings" /></td>
                            </tr>
                        )
                            :
                            DataSource.map((user, index) =>
                                <tr key={index}>

                                    <td>{user.kno}</td>
                                    <td>{user.cname}</td>
                                    <td>{user.mhthev}</td>
                                    <td>{user.gaythev}</td>
                                    <td>{user.kapatbill}</td>
                                    <td>{user.hptabill}</td>
                                    <td>{user.ikapatliter}</td>
                                    <td>{user.ikapatbill}</td>
                                    <td>{user.mhvah}</td>
                                    <td>{user.gayvah}</td>
                                    <td><UilEditAlt onClick={() => {opensr(user.kno)}} className="icsettings" /></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Settings
