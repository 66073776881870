import React, { useEffect, useRef, useState } from 'react'
import './Ratetable.css'
import { ToastContainer, toast } from 'react-toastify';
import back from "../../imgs/backexam.png";
import copy from "../../imgs/copy.png";
import { Face6TwoTone, FaxTwoTone } from '@mui/icons-material';
import axios from 'axios';

const Ratetable = (props) => {
    const [newedit, setNewedit] = useState("new");
    const [wait, setWait] = useState(false);
    const [neweditratib, setNeweditratib] = useState("new");
    const [input, setInput] = useState({ type: "", fat: "", snf: "", vibhag: "", vibh1: "", vibh2: "", vibh3: "", vibh4: "", vibh5: "", vibh6: "", vibh7: "", vibh8: "", vibh1gay: "", vibh2gay: "", vibh3gay: "", vibh4gay: "", vibh5gay: "", vibh6gay: "", vibh7gay: "", vibh8gay: "", centerto: "" });
    const [ratibrate, setRatibrate] = useState({ mhrate: "", gayrate: "" });
    const [startFat, setStartFat] = useState(0);
    const [endFat, setEndFat] = useState(0);
    const [mainvalue, setmainvalue] = useState(0);
    const [startSNF, setStartSNF] = useState(0);
    const [endSNF, setEndSNF] = useState(0);
    const [cellValues, setCellValues] = useState({});
    const [fatDifferences, setFatDifferences] = useState([]);
    const [snfDifferences, setSNFDifferences] = useState([]);
    const [rowindexmain, setrowindexmain] = useState(0);
    const [colindexmain, setcolindexmain] = useState(0);
    const refshowold = useRef(null);
    const refoldclose = useRef(null);
    const refnextrate = useRef(null);
    const [DataSource, setDataSource] = useState([]);
    const refnextrateclose = useRef(null);
    const refcopy = useRef(null);
    const refcopyclose = useRef(null);
    const [triggerEffect, setTriggerEffect] = useState(0);
    const inputRefs = useRef([]);

    const notify = (msg) => toast(msg);
    const notifyerror = (msg) => toast.error(msg);

    const handleshowold = (contact) => {
        // if(input.vibhag === "दरपत्रक विभाग-१")
        // {
        checkexist();
        // }
    }


    const copyratetable = async (event) => {
        setWait(true);
        var mainuser2 = jsEncode.encode("mamapr", 125);
        event.preventDefault();

        let rawData = {
            mainuser: localStorage.getItem("contact"),
            copymobno: input.centerto,
            type: input.type,
            mainuser2: mainuser2,
            flag: newedit
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
       
        axios.post('https://idairy.co.in/dairy/php/cust/copyratetable.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //console.log(response.data);
            if(response.data === "Records copied successfully.")
            {
                notify(input.type +  " चे दरपत्रक " + input.centerto + " केंद्रास कॉपी झाले!")
                handlecopyclose();
            }
            else 
            {
                notifyerror("पुन्हा प्रयत्न करा!");
            }
            setWait(false);
        }).catch(err => {
            console.log(err);
            setWait(false);
        });
    }


    async function getkendralist() {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);
        axios.post('https://idairy.co.in/dairy/php/cust/getkendra2.php', { mainuser: localStorage.getItem("contactplant"), mainuser2: mainuser2 }).then(function sample(response) {
            if (response.data) {
                setDataSource(response.data);
                setTimeout(() => {
                    props.setProgress(100);
                }, 200);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        getratibrate();
    }, [])
    async function checkexist() {

        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);

        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/krateexistvibhag.php', { vibhag: 'दरपत्रक विभाग-१', type: input.type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 });
            if (response.data.length > 0) {
                refshowold.current.click();
            }
            else {
                setStartFat(0);
                setEndFat(0);
                setmainvalue(0);
                setStartSNF(0);
                setEndSNF(0);
                setCellValues({});
                setFatDifferences([]);
                setSNFDifferences([]);
                setrowindexmain(0);
                setcolindexmain(0);
                createTable();
            }
        } catch (error) {
            console.log(error);
            refoldclose.current.click();
        }
    }

    const handleoldclose = () => {
        refoldclose.current.click();
    }

    const handlenextrate = () => {
        refnextrate.current.click();
        getoldnextrates();
    }

    const handlenextrateclose = () => {
        refnextrateclose.current.click();
    }

    const handlecopy = () => {
        refcopy.current.click();
    }

    const handlecopyclose = () => {
        refcopyclose.current.click();
    }

    let formattedData = [];
    let currentFat = null;

    const handleChange = async (event) => {

        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setInput(value => ({ ...value, [name]: valuecurrent }))

    }

    useEffect(() => {
        if (input.type === "म्हैस" || input.type === "गाय") {
            if (input.vibhag !== "विभाग निवडा") {   //input.vibhag === "दरपत्रक विभाग-१" || input.vibhag === "दरपत्रक विभाग-२"

                handleshowold();
            }
        }
    }, [input.type])

    useEffect(() => {

        if (input.vibhag === "दरपत्रक विभाग-१") {
            if (input.vibhag !== "विभाग निवडा") {
                getoldnextrates();
                handleshowold();
            }
        }
        else {
            getoldnextrates();
        }
    }, [input.vibhag])

    const handleChangeratib = async (event) => {
        const name = event.target.name;
        let valuecurrent = "";
        valuecurrent = event.target.value;
        setRatibrate(value => ({ ...value, [name]: valuecurrent }))
    }

    async function getratetable() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        props.setProgress(30);
        setTimeout(() => {
            props.setProgress(50);
        }, 200);

        let typeid = null;
        if (input.type === "म्हैस") {
            typeid = "1";
        }
        else {
            typeid = "2";
        }
        try {
            const response = await axios.post('https://idairy.co.in/dairy/php/cust/getkratevibhag.php', { typeid: typeid, vibhag: 'दरपत्रक विभाग-१', type: input.type, mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 });
            if (response.data.length > 0) {
                //  console.log(response.data);
                setNewedit("edit");
                if (input.type === "म्हैस") {
                    localStorage.setItem("mhrates", JSON.stringify(response.data));
                }
                else if (input.type === "गाय") {
                    localStorage.setItem("gayrates", JSON.stringify(response.data));
                }
                const groupedData = response.data.reduce((acc, item) => {
                    const fat = parseFloat(item.fat);
                    const snf = parseFloat(item.snf);

                    if (!acc[fat]) {
                        acc[fat] = [];
                    }
                    acc[fat].push(snf);

                    return acc;
                }, {});

                // Find smallest and largest 'fat'
                const fatValues = Object.keys(groupedData).map(parseFloat);
                const smallestFat = Math.min(...fatValues);
                const largestFat = Math.max(...fatValues);

                setStartFat(smallestFat);
                setEndFat(largestFat);

                const snfValues = response.data.map(item => parseFloat(item.snf));

                // Find smallest and largest 'snf'
                const smallestSnf = Math.min(...snfValues);
                const largestSnf = Math.max(...snfValues);

                setStartSNF(smallestSnf);
                setEndSNF(largestSnf);


                let responseData = response.data;

                let formattedData = [];
                let currentFat = null;
                let currentRate = null;

                // responseData.forEach((item) => {

                //     const { fat, snf, rate} = item;

                //     if (fat !== currentFat) {
                //         currentFat = fat;
                //         formattedData.push([{ fat, snf, rate }]);
                //     } else {
                //         formattedData[formattedData.length - 1].push({ fat, snf, rate });
                //     }
                // });

                responseData.forEach((item) => {
                    const { fat, snf, rate } = item;

                    // Ensure rate is treated as a number
                    let adjustedRate = parseFloat(rate);  // Convert rate to a number

                    // Adjust the rate based on the value of input.vibhag
                    if (input.vibhag === 'दरपत्रक विभाग-२') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh2);  // Add 1 to rate
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh2gay);
                        }
                    } else if (input.vibhag === 'दरपत्रक विभाग-३') {
                        if (input.type === 'म्हैस') {

                            adjustedRate += parseFloat(input.vibh3);   // Add 1 to rate
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh3gay);
                        }
                    } else if (input.vibhag === 'दरपत्रक विभाग-४') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh4);
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh4gay);
                        }
                    }
                    else if (input.vibhag === 'दरपत्रक विभाग-५') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh5);
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh5gay);
                        }
                    }
                    else if (input.vibhag === 'दरपत्रक विभाग-६') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh6);
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh6gay);
                        }
                    }
                    else if (input.vibhag === 'दरपत्रक विभाग-७') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh7);
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh7gay);
                        }
                    }
                    else if (input.vibhag === 'दरपत्रक विभाग-८') {
                        if (input.type === 'म्हैस') {
                            adjustedRate += parseFloat(input.vibh8);
                        }
                        else {
                            adjustedRate += parseFloat(input.vibh8gay);
                        }
                    }



                    // Add the adjusted rate to formattedData
                    if (fat !== currentFat) {
                        currentFat = fat;
                        formattedData.push([{ fat, snf, rate: adjustedRate.toFixed(2) }]);  // Format rate to 2 decimal places
                    } else {
                        formattedData[formattedData.length - 1].push({ fat, snf, rate: adjustedRate.toFixed(2) });  // Format rate to 2 decimal places
                    }
                });



                generateTable(formattedData);
                // createTable2(formattedData);
            }
            else {
                let formattedData = [];
                let currentFat = null;


                generateTable(formattedData);
                // createTable2(formattedData);
                refoldclose.current.click();

            }


        } catch (error) {
            console.log(error);
            refoldclose.current.click();
        }
    }


    function generateTable(data) {
        const table = document.getElementById('myTable');
        data.sort((a, b) => parseFloat(a[0].fat) - parseFloat(b[0].fat));
        // Clear existing table content
        table.innerHTML = '';

        // Create the table header row
        const headerRow = table.insertRow();
        const labelCell = headerRow.insertCell();
        const labelSpan = document.createElement('span');
        labelSpan.textContent = 'Fat/SNF';
        labelCell.appendChild(labelSpan);
        labelCell.classList.add('non-editable');
        // Create the empty cell for the first column
        // const emptyCell = headerRow.insertCell();
        // emptyCell.classList.add('non-editable'); // Add the CSS class

        // Create the header cells for snf values
        for (let i = 0; i < data[0].length; i++) {
            const snfCell = headerRow.insertCell();
            snfCell.textContent = data[0][i].snf;
            snfCell.classList.add('non-editable');
        }

        // Create the table body
        for (let i = 0; i < data.length; i++) {
            const row = table.insertRow();

            // Create the fat cell for each row
            const fatCell = row.insertCell();
            fatCell.textContent = data[i][0].fat;
            fatCell.classList.add('non-editable'); // Add the CSS class

            // Create the data cells for each snf value
            for (let j = 0; j < data[i].length; j++) {
                const cell = row.insertCell();
                const rate = data[i][j].rate;
                cell.textContent = rate;
                cell.addEventListener('click', handleCellClick);
            }
        }
        handleoldclose();
    }

    const handleCellClick = (rowIndex, colIndex, currentValue) => {
        setrowindexmain(rowIndex);
        setcolindexmain(colIndex);
        const table = document.getElementById('myTable');
        let newValue = 0;
        // Attach event listeners to each cell
        const cells = table.getElementsByTagName('td');
        for (let i = 0; i < cells.length; i++) {
            cells[i].addEventListener('click', handleCellClick2);
        }

        // Event handler for cell click
        function handleCellClick2(event) {
            const cell = event.target;

            // Check if the cell is already in edit mode
            if (cell.getAttribute('contenteditable') === 'true') {
                return; // Cell is already being edited
            }

            // Store the current value of the cell
            currentValue = cell.textContent.trim();

            // Enable editing mode
            cell.setAttribute('contenteditable', 'true');
            cell.focus();

            table.addEventListener('focusin', function (event) {
                const target = event.target;

                // Check if the target element is a cell
                if (target.tagName === 'TD') {
                    // Apply a different background color when the cell is in edit mode
                    target.style.backgroundColor = 'lightblue';
                    // Get the current cell's row and column index
                    const rowIndex = target.parentElement.rowIndex;
                    const colIndex = target.cellIndex;

                    if (parseFloat(colIndex) > 2) {
                        // Get the values of the previous two cells
                        const previousCellValue = parseFloat(table.rows[rowIndex].cells[colIndex - 1].textContent) || 0;
                        const previousMinusOneCellValue = parseFloat(table.rows[rowIndex].cells[colIndex - 2].textContent) || 0;

                        // Calculate the sum of the previous two cell values
                        if (previousCellValue > previousMinusOneCellValue) {
                            const sum = previousCellValue - previousMinusOneCellValue;
                            // Update the current cell with the sum
                            const temp = sum + previousCellValue;
                            target.textContent = parseFloat(temp).toFixed(2);
                        }
                    }
                    else if (parseFloat(colIndex) === 1) {
                        if (parseFloat(rowIndex) > 2) {
                            const previousCellValue = parseFloat(table.rows[rowIndex - 1].cells[colIndex].textContent) || 0;
                            const previousMinusOneCellValue = parseFloat(table.rows[rowIndex - 2].cells[colIndex].textContent) || 0;

                            if (previousCellValue > previousMinusOneCellValue) {
                                const sum = previousCellValue - previousMinusOneCellValue;
                                // Update the current cell with the sum
                                const temp = sum + previousCellValue;
                                target.textContent = parseFloat(temp).toFixed(2);
                            }
                        }
                    }
                }
            });

            table.addEventListener('focusout', function (event) {
                const target = event.target;

                // Check if the target element is a cell
                if (target.tagName === 'TD') {
                    // Reset the background color when the cell is not in edit mode
                    target.style.backgroundColor = '';
                }
            });

            // Listen for the Enter key press to save the new value
            cell.addEventListener('keydown', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();

                    // Get the new value from the edited cell
                    const newValue11 = cell.textContent.trim();

                    // Disable editing mode
                    cell.setAttribute('contenteditable', 'false');

                    // Update the cell's content with the new value
                    cell.textContent = newValue11;

                    setmainvalue(newValue11);
                    setTriggerEffect(prevTrigger => prevTrigger + 1);
                }

            });

            // Listen for focusout event to save the new value when the cell loses focus
            cell.addEventListener('focusout', function () {
                // Get the new value from the edited cell
                const newValue12 = cell.textContent.trim();

                // Disable editing mode
                cell.setAttribute('contenteditable', 'false');

                // Update the cell's content with the new value
                cell.textContent = parseFloat(newValue12).toFixed(2);

                setmainvalue(parseFloat(newValue12).toFixed(2));
                setTriggerEffect(prevTrigger => prevTrigger + 1);
                if (parseFloat(newValue12).toFixed(2) !== "") {
                    if (!isValidNumber(parseFloat(newValue12).toFixed(2))) {
                        setmainvalue(0);
                        cell.textContent = 0.00;

                        return;
                    }
                }
            });
        }
    };

    function isValidNumber(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }



    useEffect(() => {

        const newValue = parseFloat(mainvalue).toFixed(2);
        // console.log(newValue);
        if (newValue !== null) {

            const updatedValues = { ...cellValues };
            updatedValues[`${rowindexmain}-${colindexmain}`] = newValue;
            setCellValues(updatedValues);

            if (colindexmain === startSNF && rowindexmain !== startFat) {
                const previousValue = cellValues[`${rowindexmain - 0.1}-${colindexmain}`] || '';
                const difference = newValue - previousValue;

                const updatedDifferences = [...fatDifferences];
                updatedDifferences[rowindexmain] = difference;
                setFatDifferences(updatedDifferences);
            }

            if (rowindexmain === startFat && colindexmain !== startSNF) {
                const previousValue = cellValues[`${rowindexmain}-${colindexmain - 0.1}`] || '';
                const difference = newValue - previousValue;

                const updatedDifferences = [...snfDifferences];
                updatedDifferences[colindexmain] = difference;
                setSNFDifferences(updatedDifferences);
            }
        }

    }, [mainvalue, triggerEffect])

    const saveratetable = () => {

        if (startFat > 0 && endFat > 0 && startSNF > 0 && endSNF > 0) {

            const table = document.getElementById('myTable');
            let query = 'INSERT INTO krate (vibhag,type,rate, fat, snf,mainuser) VALUES ';
            for (let i = 1; i < table.rows.length; i++) {
                const row = table.rows[i];

                // Iterate over each cell in the row
                for (let j = 1; j < row.cells.length; j++) {
                    const cell = row.cells[j];
                    const value = cell.textContent;
                    const currentFat = row.cells[0].textContent;
                    const currentSNF = table.rows[0].cells[j].textContent;

                    if (value === null || value === "") {
                        notifyerror("फॅट -" + currentFat + " ऐस एन एफ -" + currentSNF + " चा दर उपलब्ध नाही!")
                        return;
                    }

                    query += `(${"'" + input.vibhag + "'"},${"'" + input.type + "'"},${value}, ${currentFat}, ${currentSNF}, ${"'" + localStorage.getItem("contact") + "'"})`;

                    // Check if it's the last cell in the last row
                    if (i === table.rows.length - 1 && j === row.cells.length - 1) {
                        query += ';'; // Finalize the query
                    } else {
                        query += ', '; // Add a comma to separate multiple rows
                    }
                }
            }
            var mainuser2 = jsEncode.encode("mamapr", 125);
            let rawData = {
                query: query,
                mainuser: localStorage.getItem("contact"),
                mainuser2: mainuser2,
                type: input.type,
                vibhag: input.vibhag
            }
            rawData = JSON.stringify(rawData)

            let formData = new FormData()
            formData.append('data', rawData)

            axios.post('https://idairy.co.in/dairy/php/cust/savekratevibhag.php', formData, {
                'Content-Type': 'multipart/form-data',

            }).then(function sample(response) {
                //console.log(response.data);
                if (response.data.trim() === "Successfully saved.") {
                    notify("नवीन दरपत्रक तयार झाले!");
                    getratetable();
                    // handlenew();
                }

            }).catch(err => {
                console.log(err);
            });
        }
        else {
            notifyerror("फॅट व ऐस एन एफ ची माहिती भरा.")
        }
    }

    const saveratibrate = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            mhrate: ratibrate.mhrate,
            gayrate: ratibrate.gayrate,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
            flag: neweditratib,
        }
        rawData = JSON.stringify(rawData)

        let formData = new FormData();
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/saveratibrate.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            if (response.data.trim() === "Saved.") {
                notify("नवीन रतीब दर लागू झाले!");
                //getratetable();   
            }

        }).catch(err => {
            console.log(err);
        });
    }


    const updatenextrate = () => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            vibh1: input.vibh1,
            vibh2: input.vibh2,
            vibh3: input.vibh3,
            vibh4: input.vibh4,
            vibh5: input.vibh5,
            vibh6: input.vibh6,
            vibh7: input.vibh7,
            vibh8: input.vibh8,
            vibh1gay: input.vibh1gay,
            vibh2gay: input.vibh2gay,
            vibh3gay: input.vibh3gay,
            vibh4gay: input.vibh4gay,
            vibh5gay: input.vibh5gay,
            vibh6gay: input.vibh6gay,
            vibh7gay: input.vibh7gay,
            vibh8gay: input.vibh8gay,
            mainuser: localStorage.getItem("contact"),
            mainuser2: mainuser2,
        }
        rawData = JSON.stringify(rawData)

        let formData = new FormData();
        formData.append('data', rawData)

        axios.post('https://idairy.co.in/dairy/php/cust/updatenextrate.php', formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            //  console.log(response.data);
            if (response.data.trim() === "Updated.") {
                notify("पुढील सर्व दरपत्रक तयार झाले!");
                handlenextrateclose();
                //getratetable();   
            }

        }).catch(err => {
            console.log(err);
        });
    }

    async function getratibrate() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getratibrate.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data && response.data.length > 0) {

                response.data.map((user, index) =>
                    setRatibrate({ mhrate: user.mhrate, gayrate: user.gayrate }),
                    setNeweditratib("edit"),
                )
            }
            getkendralist();
        }).catch(err => {
            console.log(err);
        });
    }

    async function getoldnextrates() {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        axios.post('https://idairy.co.in/dairy/php/cust/getoldnextrates.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

            if (response.data && response.data.length > 0) {

                response.data.map((user, index) =>
                    setInput(prevInput => ({
                        ...prevInput,
                        vibh1: user.vibh1,
                        vibh2: user.vibh2,
                        vibh3: user.vibh3,
                        vibh4: user.vibh4,
                        vibh5: user.vibh5,
                        vibh6: user.vibh6,
                        vibh7: user.vibh7,
                        vibh8: user.vibh8,
                        vibh1gay: user.vibh1gay,
                        vibh2gay: user.vibh2gay,
                        vibh3gay: user.vibh3gay,
                        vibh4gay: user.vibh4gay,
                        vibh5gay: user.vibh5gay,
                        vibh6gay: user.vibh6gay,
                        vibh7gay: user.vibh7gay,
                        vibh8gay: user.vibh8gay
                    }))
                )

            }
        }).catch(err => {
            console.log(err);
        });
    }


    const handleGenerateClick = () => {

        const updatedValues = { ...cellValues };

        // Generate values for the remaining cells


        for (let fat = startFat + 0.1; fat <= endFat; fat += 0.1) {
            for (let snf = startSNF + 0.1; snf <= endSNF; snf += 0.1) {

                const cellKey = `${fat.toFixed(1)}-${snf.toFixed(1)}`;

                const prevvalue = parseFloat(updatedValues[`${(fat - 0.1).toFixed(1)}-${(snf - 0.1).toFixed(1)}`]) || 0;
                const prevvalue1 = parseFloat(updatedValues[`${fat.toFixed(1)}-${(snf - 0.1).toFixed(1)}`]) || 0;
                const prevvalueabove = parseFloat(updatedValues[`${(fat - 0.1).toFixed(1)}-${snf.toFixed(1)}`]) || 0;

                const diffrance = prevvalue1 - prevvalue;
                const diff2 = prevvalueabove + diffrance;
                updatedValues[cellKey] = parseFloat(diff2).toFixed(2);
                // console.log(updatedValues[cellKey]);
            }
        }
        setCellValues(updatedValues);
    };

    const handleStartFatChange = (event) => {
        setStartFat(parseFloat(event.target.value));
    };

    const handleEndFatChange = (event) => {
        setEndFat(parseFloat(event.target.value));
    };

    const handleStartSNFChange = (event) => {
        setStartSNF(parseFloat(event.target.value));
    };

    const handleEndSNFChange = (event) => {
        setEndSNF(parseFloat(event.target.value));
    };


    const handlenew = () => {
        window.location.reload();
    };

    const createTable = () => {

        const rows = [];
        const cols = [];

        // Generate column headers
        for (let snf = startSNF; snf <= endSNF; snf += 0.1) {
            cols.push(<th key={snf.toFixed(1)}>{snf.toFixed(1)}</th>);
        }

        // Generate row headers and table cells

        for (let fat = startFat; fat <= endFat; fat += 0.1) {
            const roundedFat = fat.toFixed(1);
            const cells = [];

            for (let snf = startSNF; snf <= endSNF; snf += 0.1) {
                const roundedSNF = snf.toFixed(1);
                const cellKey = `${roundedFat}-${roundedSNF}`;
                //  console.log("Cellkey- ",cellKey);
                const cellValue = cellValues[cellKey] || '';
                cells.push(
                    <td
                        key={cellKey}
                        onClick={() => handleCellClick(roundedFat, roundedSNF, cellValue)}
                    >
                        {cellValue}
                    </td>
                );
            }

            rows.push(
                <tr key={roundedFat}>
                    <th>{roundedFat}</th>
                    {cells}
                </tr>
            );
        }

        return (
            <div class="table-container">
                <table id='myTable' className="table table-bordered">
                    <thead>
                        <tr>
                            <th className='thsmall'>Fat/SNF</th>
                            {cols}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    };


    function handleEnterKey(event, currentIndex) {

        if (event.keyCode === 8) {
            if (event.target.name === "time") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    time: '',
                }));
            }
            else if (event.target.name === "type") {
                setInput((prevInputSettings) => ({
                    ...prevInputSettings,
                    type: '',
                }));
            }
        }

        if (event.key === 'Backspace' && (
            (event.target.tagName === 'INPUT' && event.target.value === '') ||
            (event.target.tagName === 'SELECT' && (
                (event.target.name === 'type' && event.target.value === 'प्रकार निवडा') ||
                (event.target.name === 'time' && event.target.value === 'वेळ निवडा')
            ))
        )) {
            event.preventDefault();

            let prevIndex = currentIndex - 1;
            while (prevIndex >= 0) {
                const prevElement = inputRefs.current[prevIndex];
                if (prevElement) {
                    if (
                        (prevElement.tagName === 'INPUT' || prevElement.tagName === 'SELECT') &&
                        prevElement !== event.target
                    ) {
                        prevElement.focus();
                        break;
                    } else {
                        prevIndex--;
                    }
                } else {
                    prevIndex--;
                }
            }
        }


        if (event.key === 'Enter' || event.key === "Tab") {
            event.preventDefault();
            let nextIndex = null;
            nextIndex = currentIndex + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    }

    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refnextrate}
                data-bs-target="#nextratediff"
            >
            </button>

            <div className="modal fade" id="nextratediff" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refnextrateclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">दरपत्रक फरक सेट करा. (₹ मध्ये)</h4>


                            <div className="raterow">

                                <div className="rowcolumn">
                                    <span className='labelratered'>म्हैस</span>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-1</span>

                                        <input className="form-control vibh"
                                            id="vibh1"
                                            name="vibh1"
                                            onKeyDown={e => handleEnterKey(e, 22)} ref={ref => (inputRefs.current[22] = ref)}
                                            type="number"
                                            disabled
                                            onChange={handleChange}
                                            value={input.vibh1}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-2</span>

                                        <input className="form-control vibh"
                                            id="vibh2"
                                            name="vibh2"
                                            value={input.vibh2}
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-3</span>

                                        <input className="form-control vibh"
                                            id="vibh3"
                                            name="vibh3"
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            value={input.vibh3}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-4</span>

                                        <input className="form-control vibh"
                                            id="vibh4"
                                            name="vibh4"
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            value={input.vibh4}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-5</span>

                                        <input className="form-control vibh"
                                            id="vibh5"
                                            name="vibh5"
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            value={input.vibh5}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-6</span>

                                        <input className="form-control vibh"
                                            id="vibh6"
                                            name="vibh6"
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            value={input.vibh6}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-7</span>

                                        <input className="form-control vibh"
                                            id="vibh7"
                                            name="vibh7"
                                            onKeyDown={e => handleEnterKey(e, 23)} ref={ref => (inputRefs.current[23] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            value={input.vibh7}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-8</span>

                                        <input className="form-control vibh"
                                            id="vibh8"
                                            name="vibh8"
                                            onKeyDown={e => handleEnterKey(e, 24)} ref={ref => (inputRefs.current[24] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            value={input.vibh8}
                                            autoComplete='off' />

                                    </div>

                                </div>

                                <div className="rowcolumn">
                                    <span className='labelratered'>गाय</span>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-1</span>

                                        <input className="form-control vibh"
                                            id="vibh1gay"
                                            disabled
                                            name="vibh1gay"
                                            onKeyDown={e => handleEnterKey(e, 26)} ref={ref => (inputRefs.current[26] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            value={input.vibh1gay}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-2</span>

                                        <input className="form-control vibh"
                                            id="vibh2gay"
                                            name="vibh2gay"
                                            value={input.vibh2gay}
                                            onKeyDown={e => handleEnterKey(e, 27)} ref={ref => (inputRefs.current[27] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-3</span>

                                        <input className="form-control vibh"
                                            id="vibh3gay"
                                            name="vibh3gay"
                                            onKeyDown={e => handleEnterKey(e, 28)} ref={ref => (inputRefs.current[28] = ref)}
                                            type="number"
                                            value={input.vibh3gay}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-4</span>

                                        <input className="form-control vibh"
                                            id="vibh4gay"
                                            name="vibh4gay"
                                            onKeyDown={e => handleEnterKey(e, 29)} ref={ref => (inputRefs.current[29] = ref)}
                                            type="number"
                                            value={input.vibh4gay}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-5</span>

                                        <input className="form-control vibh"
                                            id="vibh5gay"
                                            name="vibh5gay"
                                            onKeyDown={e => handleEnterKey(e, 30)} ref={ref => (inputRefs.current[30] = ref)}
                                            type="number"
                                            value={input.vibh5gay}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-6</span>

                                        <input className="form-control vibh"
                                            id="vibh6gay"
                                            name="vibh6gay"
                                            onKeyDown={e => handleEnterKey(e, 31)} ref={ref => (inputRefs.current[31] = ref)}
                                            type="number"
                                            value={input.vibh6gay}
                                            onChange={handleChange}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-7</span>

                                        <input className="form-control vibh"
                                            id="vibh7gay"
                                            name="vibh7gay"
                                            onKeyDown={e => handleEnterKey(e, 32)} ref={ref => (inputRefs.current[32] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            value={input.vibh7gay}
                                            autoComplete='off' />

                                    </div>
                                    <div className="mb-2 mx-2">
                                        <span className='labelrate'>दरपत्रक विभाग-8</span>

                                        <input className="form-control vibh"
                                            id="vibh8gay"
                                            name="vibh8gay"
                                            onKeyDown={e => handleEnterKey(e, 33)} ref={ref => (inputRefs.current[33] = ref)}
                                            type="number"
                                            onChange={handleChange}
                                            value={input.vibh8gay}
                                            autoComplete='off' />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handlenextrateclose}>बाहेर</button>
                            <button type="button" className="btn btn-danger" onClick={updatenextrate} >सेट</button>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refshowold}
                data-bs-target="#exampleModalshowold"
            >
            </button>

            <div className="modal fade" id="exampleModalshowold" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refoldclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-title" id="exampleModalLabel">जुने दरपत्रक दाखवायचे?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleoldclose}  >नाही</button>
                            <button type="button" className="btn btn-danger" onClick={getratetable} >होय</button>
                        </div>
                    </div>
                </div>
            </div>


            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                hidden="{true}"
                ref={refcopy}
                data-bs-target="#copyratetable"
            >
            </button>

            <div className="modal fade" id="copyratetable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={refcopyclose} aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 className="modal-title" id="exampleModalLabel">विभाग १ ({input.type}) चे दरपत्रक दुसऱ्या केंद्रासाठी कॉपी करायचे?</h6>

                            <div className='line2' />

                            <div className="rowcenter">
                                <div className="mb-2 mx-2 rate">
                                    <span className='subheadsank'>केंद्र निवडा</span>
                                    <select value={input.centerto} name='centerto' id='centerto' onChange={handleChange} className="form-control aa" >

                                        {
                                            DataSource.map((user, index) =>
                                                <option key={index} value={user.mobno}>{user.city + " (" + user.mobno + ")"}</option>
                                            )
                                        }

                                    </select>
                                </div>
                                <img className='copylogo' src={copy} alt="" />

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handlecopyclose}  >बाहेर</button>
                            {
                                wait ? 
                                    <button type="button" className="btn btn-danger">लोडिंग..</button>
                                :
                                    <button type="button" className="btn btn-danger" onClick={copyratetable} >कॉपी करा</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="ratemain">
                <div className="firstrow">
                    <img onClick={props.visibility} className='back' src={back} alt="" />
                    <span>पहिल्या फॅटची आडवी ओळ व पहिल्या एस.एन.एफ चा पहिला कोलम भरून ऑटोकम्प्लीट करा.</span>

                    <div className="rratemain">
                        <div className="ratibrate">
                            <span className='subhead'>म्हैस रतीब दर </span>
                            <div className="mb-2">
                                <input type="text" className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="mhrate" name="mhrate" value={ratibrate.mhrate} onChange={handleChangeratib} ></input>
                            </div>
                        </div>
                        <div className="ratibrate">
                            <span className='subhead'>गाय रतीब दर </span>
                            <div className="mb-2">
                                <input type="text" className={newedit === "new" ? "form-control inputfontkhlarge" : "form-control inputfontkhlarge editmode"} autoComplete='off' id="gayrate" name="gayrate" value={ratibrate.gayrate} onChange={handleChangeratib} ></input>
                            </div>
                        </div>
                        <button onClick={saveratibrate} type='button' className="btn btn-primary">{neweditratib === "new" ? "सेव्ह" : "अपडेट"}</button>
                    </div>
                </div>
                <div className='line' />
                <div className="selection">

                    <div className='vline'></div>

                    <div className="mb-2 mx-2 rate">
                        <span className='subheadsank'>दरपत्रक विभाग</span>
                        <select disabled={newedit === "edit" ? 'true' : ''} onKeyDown={e => handleEnterKey(e, 0)} ref={ref => (inputRefs.current[0] = ref)} value={input.vibhag} name='vibhag' id='vibhag' onChange={handleChange} className="form-control aa" >
                            <option key={0} value="विभाग निवडा">विभाग निवडा</option>
                            <option key={1} value="दरपत्रक विभाग-१">दरपत्रक विभाग-१</option>
                            <option key={2} value="दरपत्रक विभाग-२">दरपत्रक विभाग-२</option>
                            <option key={3} value="दरपत्रक विभाग-३">दरपत्रक विभाग-३</option>
                            <option key={4} value="दरपत्रक विभाग-४">दरपत्रक विभाग-४</option>
                            <option key={5} value="दरपत्रक विभाग-५">दरपत्रक विभाग-५</option>
                            <option key={6} value="दरपत्रक विभाग-६">दरपत्रक विभाग-६</option>
                            <option key={7} value="दरपत्रक विभाग-७">दरपत्रक विभाग-७</option>
                            <option key={8} value="दरपत्रक विभाग-८">दरपत्रक विभाग-८</option>
                        </select>
                    </div>

                    <div className="mb-2 mx-2">
                        <span className='subheadsank'>
                            प्रकार
                        </span>
                        <select disabled={input.vibhag === "विभाग निवडा" || input.vibhag === "" ? 'true' : newedit === "edit" ? 'true' : ''} onKeyDown={e => handleEnterKey(e, 1)} ref={ref => (inputRefs.current[1] = ref)} value={input.type} name='type' id='type' onChange={handleChange} className="form-control" >
                            <option key={0} value="प्रकार निवडा">प्रकार निवडा</option>
                            <option key={1} value="म्हैस">म्हैस</option>
                            <option key={2} value="गाय">गाय</option>

                        </select>
                    </div>
                    <div className='vline'></div>
                    <div className="mb-2 mx-2">
                        <span className='subhead'>फॅट </span>
                        <div className="selection">
                            <input className="form-control"
                                id="startFatInput"
                                onKeyDown={e => handleEnterKey(e, 2)} ref={ref => (inputRefs.current[2] = ref)}
                                type="number"
                                disabled={input.vibhag === "विभाग निवडा" || input.vibhag === "" ? 'true' : newedit === "edit" ? 'true' : ''}
                                step="0.1"
                                value={startFat}
                                onChange={handleStartFatChange}
                                autoComplete='off' />
                            <span>ते</span>
                            <input className="form-control" autoComplete='off'
                                id="endFatInput"
                                onKeyDown={e => handleEnterKey(e, 3)} ref={ref => (inputRefs.current[3] = ref)}
                                disabled={input.vibhag === "विभाग निवडा" || input.vibhag === "" ? 'true' : newedit === "edit" ? 'true' : ''}
                                type="number"
                                step="0.1"
                                value={endFat}
                                onChange={handleEndFatChange}
                            />
                        </div>
                    </div>

                    <div className='vline'></div>

                    <div className="mb-2 mx-2">
                        <span className='subhead'>एस.एन.एफ </span>
                        <div className="selection">
                            <input className="form-control" autoComplete='off'
                                onKeyDown={e => handleEnterKey(e, 4)} ref={ref => (inputRefs.current[4] = ref)}
                                id="startSNFInput"
                                type="number"
                                step="0.1"
                                disabled={input.vibhag === "विभाग निवडा" || input.vibhag === "" ? 'true' : newedit === "edit" ? 'true' : ''}
                                value={startSNF}
                                onChange={handleStartSNFChange}
                            />
                            <span>ते</span>
                            <input className="form-control" autoComplete='off'
                                id="endSNFInput"
                                onKeyDown={e => handleEnterKey(e, 5)} ref={ref => (inputRefs.current[5] = ref)}
                                type="number"
                                disabled={input.vibhag === "विभाग निवडा" || input.vibhag === "" ? 'true' : newedit === "edit" ? 'true' : ''}
                                step="0.1"
                                value={endSNF}
                                onChange={handleEndSNFChange}
                            />
                        </div>
                    </div>

                    <div className="bb">
                        <div className="a">asdf</div>
                        <div className="selection">
                            {
                                input.vibhag === "दरपत्रक विभाग-१" && input.type !== "प्रकार निवडा"
                                    ?
                                    <>
                                        <button disabled={newedit === "edit" ? 'true' : ''} onClick={handleGenerateClick} type='button' className="btn btn-primary  mx-1">ऑटोकम्प्लीट</button>
                                        <button onClick={saveratetable} type='button' className="btn btn-primary">{newedit === "new" ? "सेव्ह" : "अपडेट"}</button>
                                        <span> | </span>
                                        <button onClick={handlenextrate} type='button' className="btn btn-primary">{"फरक"}</button>

                                        {
                                            newedit === "edit" ?
                                                localStorage.getItem("contact") && localStorage.getItem("contact").includes("-") ?
                                                    ''
                                                    :
                                                    <button type="button" id='copy' className="btn btn-success mx-1 sanksmall" onClick={handlecopy}>कॉपी</button>

                                                : ''
                                        }
                                    </>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>

                {createTable()}
                <ToastContainer />
            </div>
        </>
    );
};

export default Ratetable;
