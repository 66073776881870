import React, { useContext, useEffect, useState } from 'react'
import './Treport.css'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import refresh from "../../imgs/refresh.png";
import close from "../../imgs/close.png";
import { useRef } from 'react';
import axios from 'axios';

const Treport = (props) => {

  const reflogout = useRef(null);
  const refdeleteclose = useRef(null);
  const [DataSource, setDataSource] = useState([]);
  const [details, setDetails] = useState(false);

  const navigate = useNavigate();
  const notify = (msg) => toast(msg);

  useEffect(() => {
    if (localStorage.getItem("treport") != "") {
      setDataSource(JSON.parse(localStorage.getItem("treport")));
    }
    gettreport();
  }, [])

  var jsEncode = {
    encode: function (s, k) {
      var enc = "";
      // Make sure that the input is a string
      var str = s.toString();
      for (var i = 0; i < str.length; i++) {
        // Create block
        var a = str.charCodeAt(i);
        // Bitwise XOR
        var b = a ^ k;
        enc = enc + String.fromCharCode(b);
      }
      return enc;
    }
  };

  const showbig = () => {
    setDetails(!details);
  }

  const showsmall = () => {

  }

  async function gettreport() {

    var mainuser2 = jsEncode.encode("mamapr", 125);

    axios.post('https://idairy.co.in/dairy/php/cust/gettreport.php', { mainuser: localStorage.getItem("contact"), mainuser2: mainuser2 }).then(function sample(response) {

      if (response.data) {

        if (response.data.length > 0) {
          localStorage.setItem("treport", "");
          setDataSource(response.data);
        }
        else {
          localStorage.setItem("treport", "");
          setDataSource([]);
        }
      }
      else {
        localStorage.setItem("treport", "");
        setDataSource([]);
      }

    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <div onClick={details ? showsmall : showbig} className="boxtreport">
      {
        details ?
          // <div className={details ? DataSource && DataSource.length === 1 ? "boxtreportbig" :  DataSource && DataSource.length === 0 ? "boxtreportbig" : DataSource && DataSource.length === 2 ? "boxtreportbig2" : DataSource && DataSource.length === 3 ? "boxtreportbig4" && DataSource.length === 4 ? "boxtreportbig5" : "boxtreport" }>
          <div className={
            details 
              ? (DataSource && DataSource.length === 1) 
                ? "boxtreportbig" 
                : (DataSource && DataSource.length === 0) 
                  ? "boxtreportbig" 
                  : (DataSource && DataSource.length === 2) 
                    ? "boxtreportbig2" 
                    : (DataSource && DataSource.length === 3) 
                      ? "boxtreportbig4" 
                      : (DataSource && DataSource.length === 4) 
                        ? "boxtreportbig5" 
                        : "boxtreport" 
              : "boxtreport"
          }>
          
            <div className='rowtreport'>
              <span className='spantreportgreen'>लाईव्ह सर्व संकलन रिपोर्ट</span>
              <div className='rowtreportsimple'>
                <img onClick={gettreport} className='refreshtreport' src={refresh} alt="" />
                <img onClick={showbig} className='icontreport' src={close} alt="" />
              </div>
            </div>
            <table id="myTable" className="table table-bordered">
              <thead>
                <tr>
                  <th className='thsmall' scope="col">क्र.</th>
                  <th className='thsmall' scope="col">केंद्र</th>
                  <th className='thsmall' scope="col">म्हैस सकाळ</th>
                  <th className='thsmall' scope="col">म्हैस संध्या</th>
                  <th className='thsmall' scope="col">गाय सकाळ</th>
                  <th className='thsmall' scope="col">गाय संध्या</th>
                </tr>
              </thead>
              <tbody>
                {
                  DataSource.map((user, index) =>
                    <tr key={index}>
                      <td className='thsmall'>{index + 1}</td>
                      <td className='thsmall'>{user.city}</td>
                      <td className='thsmall'>
                        {"कलेक्शन"}<br />{user.mhmorliter}<br />
                        {"विक्री"}<br />{user.mhmorlitersangh}<br />
                        {"फरक"}<br />{parseFloat(parseFloat(user.mhmorlitersangh) - parseFloat(user.mhmorliter)).toFixed(1)}
                      </td>

                      <td className='thsmall'>
                        {"कलेक्शन"}<br />{user.mhevnliter}<br />
                        {"विक्री"}<br />{user.mhevnlitersangh}<br />
                        {"फरक"}<br />{parseFloat(parseFloat(user.mhevnlitersangh) - parseFloat(user.mhevnliter)).toFixed(1)}
                      </td>

                      <td className='thsmall'>
                        {"कलेक्शन"}<br />{user.gaymorliter}<br />
                        {"विक्री"}<br />{user.gaymorlitersangh}<br />
                        {"फरक"}<br />{parseFloat(parseFloat(user.gaymorlitersangh) - parseFloat(user.gaymorliter)).toFixed(1)}
                      </td>
                      
                      <td className='thsmall'>
                        {"कलेक्शन"}<br />{user.gayevnliter}<br />
                        {"विक्री"}<br />{user.gayevnlitersangh}<br />
                        {"फरक"}<br />{parseFloat(parseFloat(user.gayevnlitersangh) - parseFloat(user.gayevnliter)).toFixed(1)}
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          :
          <span className='spantreportgreen'>लाईव्ह संकलन रिपोर्ट</span>
      }
    </div>

  )
}

export default Treport
