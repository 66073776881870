import React, { useEffect, useState } from 'react';
import './Thevvatapprint.css';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import LatoItalicFont from "../../Fonts/Baloo2-VariableFont_wght.ttf";
import Logo from "../../imgs/dairylogo.png";

Font.register({
    family: 'Lato-Italic',
    src: LatoItalicFont, // Use the imported font variable
});

const Thevvatapprint = () => {
    const [customerData, setCustomerData] = useState({});
    const [literTotal, setLiterTotal] = useState(0);
    const [amtTotal, setAmtTotal] = useState(0);
    let totals = {};

    useEffect(() => {
        var mainuser2 = jsEncode.encode("mamapr", 125);
        let rawData = {
            bill10type: localStorage.getItem("bill10type"),
            date1: localStorage.getItem("date1print"),
            date2: localStorage.getItem("date2print"),
            mainuser: localStorage.getItem("contact"),
            mainuser2:mainuser2,
        }
        rawData = JSON.stringify(rawData)
        let formData = new FormData()
        formData.append('data', rawData)
        axios.post(`https://idairy.co.in/dairy/php/cust/getthevreport2.php`, formData, {
            'Content-Type': 'multipart/form-data',

        }).then(function sample(response) {
            setCustomerData(response.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const getDatesInRange = (date1, date2) => {
        const dates = [];
        const currentDate = new Date(date1);

        while (currentDate <= new Date(date2)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    function formatDate(inputDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(inputDate).toLocaleDateString('en-US', options);
    }

   
    if (Array.isArray(customerData) && customerData.length > 0) {
        totals = {
            ttthev: customerData.reduce((total, rowData) => total + (rowData.tthev && rowData.tthev != null ? parseFloat(rowData.tthev) : 0), 0),
           
        };
    }
  
   
    var jsEncode = {
        encode: function (s, k) {
            var enc = "";
            // Make sure that the input is a string
            var str = s.toString();
            for (var i = 0; i < str.length; i++) {
                // Create block
                var a = str.charCodeAt(i);
                // Bitwise XOR
                var b = a ^ k;
                enc = enc + String.fromCharCode(b);
            }
            return enc;
        }
    };
    return (
        <PDFViewer className='pdf'>
            <Document>

                {customerData.length > 0 ? (
                 
                        <Page size="A4" style={styles.page}>
                            <Text style={styles.latoText}>{customerData[0].kendra}</Text>
                            <Text style={styles.latoText}>{"ठेव वाटप रिपोर्ट"}</Text>
                            <Text style={styles.latoText2}>{"कालावधी : " + formatDate(localStorage.getItem("date1print")) + "   ते   " + formatDate(localStorage.getItem("date2print"))}</Text>
                            <View style={styles.imagerow}>
                                <Text style={styles.imgtext}>{"Powered by, "}</Text>
                                <Image style={styles.image} src={Logo} />
                            </View>

                            <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableHeader}>{"खाते क्रमांक"}</Text>
                                    <Text style={styles.tableHeader}>{"नाव"}</Text>
                                    <Text style={styles.tableHeaderlast}>{"एकूण ठेव रक्कम"}</Text>
                                 
                                </View>
                                {customerData.map((rowData, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={styles.tableCell}>{rowData.kno}</Text>
                                        <Text style={styles.tableCellleft}>{rowData.cname}</Text>
                                        <Text style={styles.tableCell}>{rowData.tthev && rowData.tthev !== null ? parseFloat(rowData.tthev) : 0}</Text>
                                     
                                        {/* Add more data cells as needed */}
                                    </View>
                                ))}
                                <View style={styles.tableRow3}>
                                    <Text style={styles.tableHeader}>{"एकूण"}</Text>
                                    <Text style={styles.tableHeader}>{"-"}</Text>
                                    <Text style={styles.tableHeaderlast}>{parseFloat(totals.ttthev).toFixed(2)}</Text>
                                </View>
                            </View>
                        </Page>
                ) : ""}
            </Document>
        </PDFViewer>

    );

};

// ... (styles and export)



const styles = StyleSheet.create({
    page: {
        marginTop: 20,
        paddingTop: 20,
        paddingBottom: 50,  // Adding bottom padding to ensure space at the bottom
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    header: {
        position: 'absolute',
        top: 0, // Adjust this value to change the vertical position
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
    },
    table: {
        display: 'table',
        width: '97%',
        margin: 5,
        marginTop: 15,
    },
    tableone: {
        display: 'table',
        width: '18%', // Adjust as needed to fit four tables in a row
        margin: 5,
    },
    tableCellone: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        fontSize: 8,
        fontFamily: 'Lato-Italic',
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },
    tableRowone: {
        flexDirection: 'row',
        width: '100%',
    },
    tableHeaderone: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        border: '1px solid #000',
        padding: 2,
        fontSize: 8,
        textAlign: 'center',
        flex: 1, // Remove width and use flex to distribute space equally
    },

    tableRow: {
       
        flexDirection: 'row',
        marginBottom: 1,   // Adds space between rows
        flexGrow: 1,        // Ensures rows take up remaining space properly
    },
    tableRow2: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
    },

    tableRow3: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 10,
        marginBottom: 50,   // Ensures the total row does not run into the bottom of the page
    },
    line: {
        flexDirection: 'row',
        backgroundColor: 'lightgray',
        marginTop: 5,
        height: 1,
        width: '100vw',
        marginBottom: 5,
    },
    tableHeader: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 0,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        padding: 2,
        flex: 0.5,
        borderColor: 'lightblack',
        textAlign: 'center',
        fontSize: 9,
    },
    tableHeaderlast: {
        fontFamily: 'Lato-Italic',
        fontWeight: 'bold',
        borderTopWidth: 1,    // Add border only for top side
        borderBottomWidth: 1, // Add border only for bottom side
        borderLeftWidth: 1,   // Add border only for left side
        borderRightWidth: 1,  // No border for right side
        borderStyle: 'solid', // Set the border style to 'solid'
        borderColor: 'lightblack',
        padding: 2,
        flex: 0.5,
        textAlign: 'center',
        fontSize: 9,
    },
    tableCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'center',
    },
    tableCellleft: {
        border: '0.5px solid lightgray',
        padding: 1,
        flex: 1,
        fontSize: 10,
        fontFamily: 'Lato-Italic',
        width: 2,
        textAlign: 'left',
    },
    tableCellgreen: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 10,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'green',
        width: 2,
        textAlign: 'center',
    },
    tableCellred: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 9,
        fontWeight: 'bold',
        fontFamily: 'Lato-Italic',
        color: 'red',
        width: 2,
        textAlign: 'center',
    },
    latoTextlarge: {
        fontFamily: 'Lato-Italic',
        fontSize: 15,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText: {
        fontFamily: 'Lato-Italic',
        fontSize: 14,
        marginBottom: 5,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
        marginTop: 5,
    },
    latoText2: {
        fontFamily: 'Lato-Italic',
        fontSize: 11,
        textAlign: 'center',
        width: '100vw',
        fontWeight: 'bold',
    },
    headtextsmall: {
        fontFamily: 'Lato-Italic',
        fontSize: 10,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
    },
    row1: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 140,
        paddingRight: 140,
        backgroundColor: 'lightgray',
    },
    column1: {
        display: 'flex',
        flexDirection: 'column',
        width: '19%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        width: '15%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,


    },
    column3: {
        display: 'flex',
        flexDirection: 'column',
        width: '17%',
        justifyContent: 'start',
        alignItems: 'flex-start', // Align text to the left
        paddingLeft: 5,
        paddingRight: 5,
        marginLeft: 20,
    },
    column1Row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    column1Rowgray: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 6,
        marginTop: 5,
        border: '0.5px solid black',
        backgroundColor: 'lightgray'
    },
    column1Label: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'left', // Align labels to the left
    },
    column1Value: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'right', // Align values to the right
    },
    pageBreak: {
        pageBreakBefore: 'always',
    },
    totalsRow: {
        flexDirection: 'row',
        borderTop: '1px solid #000',
        paddingTop: 5,
        paddingLeft: 2,
    },
    totalsCell: {
        border: '0.5px solid lightgray',
        padding: 0.5,
        flex: 1,
        fontSize: 8,
        width: 2,
        textAlign: 'center',
    },
    imagerow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 80, // Adjust the width as needed
        height: "auto", // Maintain aspect ratio
        marginLeft: -15,
        marginTop: 5

    },
    imgtext: {
        fontFamily: 'Lato-Italic',
        fontSize: 9,
        fontWeight: 'bold',
        textAlign: 'center', // Align labels to the left
    },
});

export default Thevvatapprint
